import commonUtils from "../../../common/commonUtils";

const state = {
  showLogin:false,
  loginType:'login',
  userInfo:null,
  navAcrossState:true,
  navSonKeyCount:0,
  parentNav:{},
  myCliNum:{
    'tcga':0,
    'geo':0
  },
  myCliDataObj:{
    'types':'',
    'dataFile':'',
    'goups':[],
    "list":[],
    sonGpCount:0,
    myCliShow:false
  },
  GEOmyCliDataObj:{
    'types':'',
    'dataFile':'',
    'goups':[],
    "list":[],
    sonGpCount:0,
    myCliShow:false
  },
  myCliDataResetCount:0,
  navSonCloseCount:0,
  geoCliDataObj:{
    list:[]
  },
  geoInit:0,
  repullMyGeoDataCount:0,
}

// getters
const getters = {

}

// actions
const actions = {

  setShowLogin: function({ commit },{isShow}) {
    commit('setShowLogin', {isShow:isShow})
  },
  setShowType: function({ commit },{type}) {
    commit('setShowType', {type:type})
  },
  setUserInfo: function({ commit },{userInfo}) {
    commit('setUserInfo', {userInfo:userInfo})
  },
  setUserInfoVo: function({ commit },{userInfoVo}) {
    commit('setUserInfoVo', {userInfoVo:userInfoVo})
  },
  setNavAcrossState: function({ commit },{acrossState}) {
    commit('setNavAcrossState', {acrossState:acrossState})
  },
  setNavSonKeyCount: function({ commit },{navKeyCount}) {

    commit('setNavSonKeyCount', {navKeyCount:navKeyCount})
  },
  setparentNav: function({ commit },{parentNav}) {

    commit('setparentNav', {parentNav:parentNav})
  },
  setMyCliNum: function({ commit },{myCliNum}) {

    commit('setMyCliNum', {myCliNum:myCliNum})
  },
  setGEOmyCliDataObj: function({ commit },myCliDataObj) {

      commit('setGEOmyCliDataObj', myCliDataObj)

  },
  setCliDataObj: function({ commit },myCliDataObj) {

      commit('setCliDataObj', myCliDataObj)

  },
  setMyCliShow: function({ commit },show) {

    commit('setMyCliShow', show)
  },
  setMyCliDataResetCount: function({ commit }) {

    commit('setMyCliDataResetCount')
  },
  setNavSonCloseCount: function({ commit }) {

    commit('setNavSonCloseCount')
  },
  setGeoCliDataObj: function({ commit },geoCliDataObj) {

    commit('setGeoCliDataObj', geoCliDataObj)
  },
  setGeoCliDataObjNotSetInit: function({ commit },geoCliDataObj) {

    commit('setGeoCliDataObjNotSetInit', geoCliDataObj)
  },
  setGeoInit: function({ commit }) {

    commit('setGeoInit')
  },
  setRepullMyGeoDataCount: function({ commit }) {

    commit('setRepullMyGeoDataCount')
  },

}

// mutations
const mutations = {
  setShowLogin (state, {isShow}) {
    state.showLogin = isShow
  },
  setShowType (state, {type}) {
    state.loginType = type
  },
  setUserInfo(state, {userInfo}){
    state.userInfo=userInfo
  },
  setUserInfoVo(state, {userInfoVo}){
    if(commonUtils.varNotNull(state.userInfo)){
      state.userInfo['userInfoVo']=userInfoVo
    }

  },
  setNavAcrossState(state, {acrossState}) {
    state.navAcrossState=acrossState
  },
  setNavSonKeyCount(state, {navKeyCount}) {

    state.navSonKeyCount=navKeyCount
  },
  setparentNav(state, {parentNav}) {

    state.parentNav=parentNav
  },
  setMyCliNum (state, {myCliNum}) {
    state.myCliNum=myCliNum
  },
  setCliDataObj (state,myCliDataObj) {
    state.myCliDataObj=myCliDataObj
  },
  setGEOmyCliDataObj (state,myCliDataObj) {
    state.GEOmyCliDataObj=myCliDataObj
  },
  setMyCliShow (state,show) {
    state.GEOmyCliDataObj['myCliShow']=show
    state.myCliDataObj['myCliShow']=show

  },
  setMyCliDataResetCount(state) {
    state.myCliDataResetCount++
  },
  setNavSonCloseCount(state) {
    state.navSonCloseCount++
  },
  setGeoCliDataObj(state,geoCliDataObj) {
    state.geoCliDataObj=geoCliDataObj

      state.geoInit++
  },
  setGeoCliDataObjNotSetInit(state,geoCliDataObj) {

    state.geoCliDataObj=geoCliDataObj

  },
  setGeoInit(state) {
    commonUtils.clearGeoCliParamParse(state.geoCliDataObj).then(geoCliDataObj=>{
      state.geoCliDataObj=geoCliDataObj
      state.geoInit++
    })
  },
  setRepullMyGeoDataCount(state) {
    state.repullMyGeoDataCount++
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
