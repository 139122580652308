import ajax from './common/ajax'
import config from './common/config'
import utils from './common/utils'
// import feedbackApi from './sci/feedbackApi'
import loginApi from "./loginApi";
import mapEchartUtils from "./common/mapEchartUtils";
import configApi from "./configApi";

export default {
  ajax,
  config,
  utils,
  // feedbackApi,
  loginApi,
  mapEchartUtils,
  configApi
}
