import ajax from "./common/ajax";
import config from "./common/config";
// eslint-disable-next-line no-unused-vars
import utils from "./common/utils";

let serverBase=config.serverBase



export default {

  getPayQrData () {

    let path='/public/config'+"/pay_qr"
    let url=serverBase+path
    let datas={ }

    return  new Promise((resolve, reject) => {
      ajax.GET(url,datas,(res)=>{
            resolve(res)
        },
        (res)=>{
            reject(res)
        })
    })

  },
  getPayTextData () {

    let path='/public/config'+"/pay_texts"
    let url=serverBase+path
    let datas={ }

    return  new Promise((resolve, reject) => {
      ajax.GET(url,datas,(res)=>{
          resolve(res)
        },
        (res)=>{
          reject(res)
        })
    })

  },
  getHomeUpdateNotice(){
    let path='/public/config'+"/getHomeUpdateNotice"
    let url=serverBase+path
    let datas={ }

    return  new Promise((resolve, reject) => {
      ajax.GET(url,datas,(res)=>{
          resolve(res)
        },
        (res)=>{
          reject(res)
        })
    })
  },
  getPayVipQrData () {

    let path='/public/config'+"/vip_qr"
    let url=serverBase+path
    let datas={ }

    return  new Promise((resolve, reject) => {
      ajax.GET(url,datas,(res)=>{
          resolve(res)
        },
        (res)=>{
          reject(res)
        })
    })

  },
  news(){
    return new Promise((resolve, reject) => {

      let path='/public/config/news'
      let url=serverBase+path
      let datas={ }

      ajax.GET(url,datas,(res)=>{
          resolve(res)
        },
        (res)=>{
          reject(res)
        })

    })
  },
  vds(){
    return new Promise((resolve, reject) => {

      let path='/public/config/vds'
      let url=serverBase+path
      let datas={ }

      ajax.GET(url,datas,(res)=>{
          resolve(res)
        },
        (res)=>{
          reject(res)
        })

    })
  },
}
