<template>
    <div class="not-google-tip-warp" v-show="showTip">
        <div>
          <a target="_blank" href="https://www.google.cn/intl/zh-CN/chrome/">当前浏览器非谷歌内核，将影响登陆和分析，请更换至以下浏览器（谷歌，360，火狐，搜狗等）</a>
          <span @click="showTip=false" class=" el-icon-circle-close close"></span>
        </div>

    </div>
</template>

<script>
  import utils from "../api/common/utils";
    export default {
        name: "NotGoogleTip",
        data(){
          return{
              showTip:false
          }
        },
        mounted() {

            let isGoogle=utils.isGoogle();
            this.showTip=!isGoogle;
            console.log(isGoogle?'is google':'not google')
            if(!isGoogle){
                setTimeout(()=>{
                    this.showTip=false
                },40*1000)
            }

        }
    }
</script>

<style scoped lang="less">


  @base:1.8;
  .not-google-tip-warp{
    width: 100%;
    height: 20px*@base;
    font-size: 12px*@base;
    line-height: 20px*@base;
    background: #66b1ff;
    text-align: center;
    color: white;

    a{
      color: white;
    }

    .close{
      cursor: pointer;
      font-size: 13px*@base;
      margin-left: 2px*@base;
      &:hover{

      }
    }

  }

</style>
