export const lang = {
    //公共
    common_search:"搜索",
    common_reset:"重置",
    common_adv:"高级检索",
    common_video:"如何精确检索",
    common_adv_title_01:"关联符",
    common_adv_title_02:"检索字段",
    common_adv_title_03:"检索词",
    common_adv_btn:"显示条件",

    document_title:'Medpulse',
    confirm:'确定',
    confirm_pay:'捐赠',
    key_point: "维护科研诚信，杜绝学术不端",
    no_data:'暂无数据',
    unknown:'未知',
    timeout_dialog_title:'温馨提示',
    timeout_dialog_conetent_html:'检测超时！请联系工作人员解决：',

    btn_cancer:'取消',
    btn_update:'修改',
    btn_upgrade:'马上升级',

    global_tip_title:'温馨提示',
    global_tip_confirm_known:'知道了',
    global_tip_confirm_ok:'好的',
    global_tip_success:'成功',
    global_tip_check_num_zero:'检测次数已用完，现在升级权限。',

    home_checkout: '请先扫码登录微信，每月可检测{1}次，谢谢。',
    contact:"联系我们",

    //顶部
    header_lang: "English",
    // header_login: "邮箱登录",
    header_login: "微信登录",
    header_login_prompt: "每月{0}次检测机会,请合理使用",
    header_quit: "退出",
    header_ret_home: "点击返回首页",
    header_click_copy_user_nickname: "点击复制用户昵称",

    header_menu_api_access:"API接入",
    header_menu_down_word_plugin:"下载Word插件",

    header_user_id:"用户ID",
    header_username:"用户名",
    header_user_nick_name:"用户昵称",
    header_user_remain_num:"剩余次数",
    header_user_pay:"捐赠",
    header_user_click_copy_userID:"点击复制用户ID",
    header_user_click_update_nickName:"点击修改昵称",
    header_user_update_nickName:"修改昵称",
    header_user_input_new_nickName:"请输入新昵称",
    header_copy_success:"复制成功",
    loginbox_msg_logout_succ:'注销成功',

    //首页
    upload_box_drag_here:"拖拽文件至此处，或点击此处",
    upload_box_upload:"上传",
    upload_box_upload_support_format:"支持格式: docx",
    checking:"检查中...",
    start_check:"开始运行",
    check_time_prefix:"大约",
    check_time_postfix:"秒后完成检查",
    check_waiting:"当前查询人数较多,正在排队中,请耐心等候...",
    upload_box_msg_size:"单个docx文献请不要超过{0}MB，谢谢。",
    upload_box_msg_not_docx:"拖入的不是docx稿件",
    check_limit_prompt:"温馨提示：本系统适配示例的原始投稿排版，其它排版可能导致识别遗漏。",


    check_option_title:"检测内容",
    check_option_ref_sim:"参考文献相似分析",
    check_option_org:"识别机构",
    check_option_author_article:"作者发文情况",
    check_option_year_stat:"参考文献年份统计",
    check_option_suspect_ref:"参考文献可疑不当引用",
    check_option_ref_risk:"参考文献学术诚信风险",
    check_option_author_risk:"作者学术诚信风险预警",
    check_option_email_risk:"通讯作者邮箱学术诚信预警",
    check_option_email_sim:"通讯作者邮箱相似风险预警",
    check_option_figcheck:"图片查重",

    check_result_down_report:"点击下载报告",
    check_result_msg_no_report:"尚无报告可下载",

    check_result_ref_sim_title:"参考文献相似度检查",
    check_result_ref_sim_prompt:"参考文献中出现的相似的文献,相似度超过80%则给予预警",
    check_result_sim_prompt:"以下文献疑似重复",
    check_result_sim_percent:"相似率",
    check_result_ref_sim_zero_result_prompt:"恭喜，参考文献中未出现重复",

    check_result_org_title:"作者机构",
    check_result_no:"序号",
    check_result_org_name:"机构名",
    check_result_org_zero_result_prompt:"当前稿件未发现有作者机构",

    check_result_author_article_title:"通讯作者文献发表情况",
    check_result_author:"作者",
    check_result_org:"机构",
    check_result_pub_article_count:"发表文献数",
    check_result_max_if:"最高IF",
    check_result_avg_if:"平均IF",
    check_result_author_article:"相关文章",
    check_result_view:"查看",
    check_result_author_aritcle_zero_result_prompt:"通讯作者未发表过其他文献",

    check_result_ref_year_stat_title:"参考文献年份统计",
    check_result_year:"年份",
    check_result_cur_year_num:"当年条数",
    check_result_count_num:"累计条数",
    check_result_cur_year_percent:"当年占比",
    check_result_count_percent:"累计占比",
    check_result_ref_zero_result_prompt:"恭喜，没有发现涉及学术诚信的参考文献。",

    check_result_ref_detail_stat_title:"参考文献详情统计（包括学术诚信风险）",
    check_result_ref_detail_stat_prompt:"参考文献中出现了学术诚信风险预警",
    check_result_fullscreen_view:"查看全部",
    check_result_journal:"期刊",
    check_result_ifs:"影响因子",
    check_result_pub_year:"发表年份",
    check_result_warning:"诚信预警",
    check_result_click_view:"点击查看",

    check_result_ref_risk_title:"参考文献可疑不当引用",
    check_result_ref_risk_prompt:"如本文所有参考文献中，同一作者文章出现>=3次，则给于预警",
    check_result_show_num:"出现次数",
    check_result_ref_no:"引文序号",
    check_result_ref_risk_zero_result_prompt:"恭喜，参考文献中未出现可疑不当引用",

    check_result_author_risk_title:"作者学术诚信风险预警",
    check_result_author_risk_prompt:"如本文作者其他文章有涉及学术诚信风险，则给于预警",
    check_result_article:"文献",
    check_result_link:"链接",
    check_result_author_risk_zero_result_prompt:"恭喜，当前稿件未发现作者涉及其他学术诚信风险",

    check_result_email_risk_title:"通讯作者邮箱学术诚信预警",
    check_result_email_risk_prompt:"系统自动匹配相同涉及学术诚信问题的邮箱",
    check_result_email:"邮箱",
    check_result_pub_date:"发布日期",
    check_result_warning_link:"预警链接",
    check_result_email_risk_zero_result_prompt:"恭喜，稿件中未出现涉及学术诚信的通讯邮箱。",

    check_result_email_sim_title:"通讯作者邮箱相似风险预警",
    check_result_email_sim_prompt:"论文工厂通常使用相近邮箱进行注册，本项中，系统自动匹配相同或相近的邮箱，仅供参考",
    check_result_email_sim_zero_result_prompt:"恭喜，稿件中未出现有相似风险的通讯邮箱。",

    check_result_title_figcheck:"Figcheck查重",

    check_result_dialog_title_postfix_year_pub:"的年度发表量",
    check_result_dialog_title_postfix_ifs:"的影响因子分布图",
    check_result_dialog_title_postfix_pub_article:"的发表文献",
    check_result_pub_count:"发表量",



    //案例及友情链接
    case_title:'图片重复案例',
    links_title:'友情链接',
    links_link_3:'国家卫健委',
    links_link_4:'国务院',
    links_link_5:'中华医学会',
    links_link_6:'国自然基金委',
    //新增 9月18号


    //登录注册
    //公共
    loginbox_input_email:'请输入邮箱',
    loginbox_input_pass:'请输入密码',
    loginbox_link_login_wechat:'微信登录',
    loginbox_link_reg:'点击注册',
    loginbox_link_find_pass:'找回密码',
    loginbox_link_login:'点击登录',
    //登录
    loginbox_login_title:'登录',
    loginbox_login:'登录',
    //注册
    loginbox_reg_title:'邮箱注册',
    loginbox_send_code:'发送验证码',
    loginbox_input_code:'请输入邮箱验证码',
    loginbox_reg:'注册',
    //找回密码
    loginbox_reset_title:'找回密码',
    loginbox_input_new_pass:'请输入新密码',
    // loginbox_confirm:'确认',
    //错误提示
    loginbox_err_email:'输入的邮箱格式不正确,请重新输入',
    loginbox_err_pass:'密码不合法,密码为6位以上数字字母组合',
    loginbox_err_code:'邮箱验证码不合法,验证码为4位字母或数字',
    //消息提示
    loginbox_msg_login_succ:'登录成功',
    loginbox_msg_login_fail:'登录失败,获取用户信息失败,请重试',
    loginbox_msg_send_limit:'发送邮件间隔限制,请稍等一会',
    loginbox_msg_reg_succ:'新用户邮箱注册成功,可以通过邮箱和密码进行登录',
    loginbox_msg_reset_succ:'密码重置成功,自动登录成功',
    loginbox_msg_reset_succ_login_fail:'密码重置成功,自动登录失败,请尝试手动登录',
    loginbox_msg_reset_fail:'密码重置失败,请重试',
    loginbox_msg_send_email_fail:'发送邮件失败',
    loginbox_msg_send_email_succ:'发送邮件成功',
    loginbox_msg_code_err:'验证码错误,错误次数已达{0}次,还能重试次数{1}次',
    loginbox_msg_code_err_no_num:'验证码错误',
    loginbox_msg_login_gt_limit:'登录错误超过限制,请过会再试',
    loginbox_msg_login_user_in_black_list:'你的账号已被锁定,暂时无法登录使用',
    loginbox_msg_login_ip_in_black_list:'你的IP已被锁定,暂时无法登录使用',
    loginbox_msg_user_pass_err:'账号或密码错误,还能重试{0}次',
    loginbox_msg_user_not_exist:'用户可能不存在,请先注册',
    loginbox_msg_user_has_exist:'用户已存在,请登陆',
    loginbox_msg_reg_fail:'账号注册失败,请重新注册',
    //邮箱验证码
    email_titile:'邮箱验证码 by Figcheck',
    email_content:'你好，请将以下验证码 0000 输入至注册页面输入框，完成注册，谢谢。',
    //头部菜单
    menu_self_check:'学术自查',
    menu_readme:'使用说明',
    menu_pub_sub:'Pubpeer订阅',
    menu_peer_review:'同行评议',
    menu_api_access:'机构',

    //付费页
    pay_intro:"Figcheck是国内首个开放式图像查重平台，自2021年9月份上线以来，即受到了广泛关注，截止2022年5月30日，已运行查重超过30万次。由于图像识别算法需使用GPU服务器（运算成本较高），为了平台能够持续迭代和维护，Figcheck即日起开始接收捐款。",
    pay_id_account:"ID账号",
    pay_remain_num:"剩余次数",
    pay_remain_num_unit:"次",
    pay_not_login:"还未登录",
    pay_action:"捐款",
    pay_money_unit_char:"￥",
    pay_money_unit_str:"元",
    pay_order_num:"订单编号",
    pay_type:"支付方式",
    pay_type_wx:"微信扫码",
    pay_type_ali:"支付宝扫码",
    pay_msg:"消息",
    pay_prompt_1:"1.单次检索最大文献数不超过10000条，更多条数请联系工作人员<a href='/individuation' target='_blank' style='cursor: pointer;color: #6674D7;font-weight: 500;'>个性化服务</a>。",
    pay_prompt_2:"2.本支付为特殊虚拟商品，不支持退款，请捐款前充分确认是否有用，谢谢。",
    pay_prompt_3:"3.请勿重复支付;支付完成后,若次数未增加,请退出并重新登录;其他问题请及时联系客服。",
    pay_prompt_4_1:"4.发票开具请联系",
    pay_prompt_4_2:"工作人员",
    pay_prompt_4_3:"。",
    pay_qcode_title:"扫码加客服微信",
    pay_qcode_prompt:"客服微信",

    pay_msg_success:'支付完成',
    pay_msg_nopay:'未支付',
    // mcarcheck

    check_result_toggle_to_list_view:"查看列表",
    check_result_toggle_to_chart_view:"查看柱状图",

    //Reflinker
    step_name_1:"期刊选择",
    step_name_2:"邮箱过滤",
    step_name_3:"邮件内容设置和预览",
    step_name_4:"群发进度",

    step_btn_prev:"上一步",
    step_btn_next:"下一步",
    step_btn_next_start_send:"开始群发",

    step_1_input_prompt:"请选择需要推广的期刊",

    journal_name:"期刊名",
    ifs:"影响因子",
    part:"分区",
    part_postfix:"区",
    journal_url:"地址链接",

    step_2_left_title:"文献概览",
    step_2_left_stat_1:"共找到",
    step_2_left_stat_2:"条 ，参考文献",
    step_2_left_stat_3:"条，可发邮箱",
    step_2_left_stat_4:"个",

    step_2_left_filter_time:"文献发表时间",
    step_2_left_filter_time_0:"年至今",
    step_2_left_filter_time_1:"当年",
    step_2_left_filter_time_2:"年",
    step_2_left_filter_time_3:"年",
    step_2_left_filter_time_year:"年",
    step_2_left_filter_time_4:"最近半年",
    step_2_left_filter_time_5:"最近3个月",

    step_2_right_title:"邮箱筛选",
    step_2_right_stat_1:"已自定义设定目标邮箱",
    step_2_right_stat_2:"个",
    step_2_right_confirm_btn:"确认设置",

    step_2_right_filter_range_separator:"至",
    step_2_right_filter_start_placeholder:"开始日期",
    step_2_right_filter_end_placeholder:"结束日期",

    step_2_right_filter_ifs:"影响因子",
    step_2_right_filter_org:"机构筛选",
    step_2_right_filter_journal:"期刊筛选",

    step_2_right_result_email_title:"可推荐邮箱",

    step_4_progress_prompt:"发送进度",
    step_4_history_title:"推送历史",
    step_4_history_col_1:"序号",
    step_4_history_col_11:"期刊",
    step_4_history_col_2:"应发邮件数",
    step_4_history_col_3:"实发邮件数",
    step_4_history_col_4:"链接打开数",
    step_4_history_col_41:"链接打开率",
    step_4_history_col_42:"邮箱打开数",
    step_4_history_col_43:"邮箱打开率",
    step_4_history_col_44:"发送进度",
    step_4_history_col_5:"发送情况",
    step_4_history_col_6:"发送时间",
    step_4_history_col_7:"操作",
    step_4_history_col_7_1:"查看报告",

    step_4_stat_dialog_title:"作者情况",
    step_4_stat_dialog_col_1:"打开邮箱",
    step_4_stat_dialog_col_2:"姓名",
    step_4_stat_dialog_col_3:"机构",
    step_4_stat_dialog_col_4:"个人关键词",
    step_4_stat_dialog_col_5:"累计文章数",
    step_4_stat_dialog_col_6:"平均IF",
    step_4_stat_dialog_col_7:"最高IF",
    step_4_stat_dialog_col_8:"相关文章",

    step_4_stat_dialog_col_view:"查看",

    step_4_stat_dialog_year_title:"的年度发表量",
    step_4_stat_dialog_ifs_title:"的影响因子分布图",
    step_4_stat_dialog_article_title:"的发表文献",

    month_picker_1:"本月",
    month_picker_2:"今年至今",
    month_picker_3:"最近六个月",

    canvas_article_count:"发表量",

    pagination: {
        goto: '前往',
        pagesize: '条/页',
        total: '共 {total} 条',
        pageClassifier: '页'
    },
    //element ui
    datepicker: {
        now: '此刻',
        today: '今天',
        cancel: '取消',
        clear: '清空',
        confirm: '确定',
        selectDate: '选择日期',
        selectTime: '选择时间',
        startDate: '开始日期',
        startTime: '开始时间',
        endDate: '结束日期',
        endTime: '结束时间',
        prevYear: '前一年',
        nextYear: '后一年',
        prevMonth: '上个月',
        nextMonth: '下个月',
        year: '年',
        month1: '1 月',
        month2: '2 月',
        month3: '3 月',
        month4: '4 月',
        month5: '5 月',
        month6: '6 月',
        month7: '7 月',
        month8: '8 月',
        month9: '9 月',
        month10: '10 月',
        month11: '11 月',
        month12: '12 月',
        // week: '周次',
        weeks: {
            sun: '日',
            mon: '一',
            tue: '二',
            wed: '三',
            thu: '四',
            fri: '五',
            sat: '六'
        },
        months: {
            jan: '一月',
            feb: '二月',
            mar: '三月',
            apr: '四月',
            may: '五月',
            jun: '六月',
            jul: '七月',
            aug: '八月',
            sep: '九月',
            oct: '十月',
            nov: '十一月',
            dec: '十二月'
        }
    },

    report_title:"推送统计报告",
    report_total_state_title:"期刊数据总计",
    report_total_state_label_1:"可推送邮件数",
    report_total_state_label_2:"文章数",
    report_total_state_label_3:"作者数",
    report_total_state_label_4:"参考文献数",

    report_cur_state_label_pre:"本次推送统计（推送时间:",
    report_cur_state_label_after:"）",
    report_cur_state_progress_label:"当前推送进度",
    report_cur_state_label_1:"作者人数",
    report_cur_state_label_2:"邮件发送数",
    report_cur_state_label_3:"邮件打开数",
    report_cur_state_label_4:"邮件打开率",
    report_cur_state_label_5:"官网打开数",
    report_cur_state_label_6:"总曝光量",
    report_cur_state_label_7:"总曝光率",

    report_chart_state_label_1:"推送趋势",
    report_chart_state_label_2:"网站访问量统计",

    report_chart_state_label_11:"文献引用",
    report_chart_state_label_21:"邮件打开量",

    report_chart_state_label_date_1:"最近一天",
    report_chart_state_label_date_2:"最近三天",
    report_chart_state_label_date_3:"最近一周",
    report_chart_state_label_date_4:"最近一个月",

    report_list_filter_label_1:"接收人",
    report_list_filter_label_2:"至",
    report_list_filter_label_3:"开始日期",
    report_list_filter_label_4:"结束日期",
    report_list_filter_label_btn_1:"查询",

    report_list_filter_label_11:"全部",
    report_list_filter_label_12:"已发送",
    report_list_filter_label_13:"未发送",

    report_list_filter_label_21:"全部",
    report_list_filter_label_22:"已读",
    report_list_filter_label_23:"未读",

    report_list_filter_label_31:"全部",
    report_list_filter_label_32:"已点链接",
    report_list_filter_label_33:"未点链接",

    report_list_table_label_1:"邮件标题",
    report_list_table_label_2:"接收方",
    report_list_table_label_3:"发件箱",

    report_list_table_label_col_1:"序号",
    report_list_table_label_col_2:"方式",
    report_list_table_label_col_21:"Email邮件",
    report_list_table_label_col_3:"接收人",
    report_list_table_label_col_4:"邮箱",
    report_list_table_label_col_41:"机构",
    report_list_table_label_col_42:"访问地区",
    report_list_table_label_col_5:"推送时间",
    report_list_table_label_col_6:"结果",
    report_list_table_label_col_7:"已读",
    report_list_table_label_col_8:"网站访问",
}
