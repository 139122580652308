/**

 <div class="link">登录工具</div>
 </div>
 *
 */

import commonConsts from "./commonConsts";
import commonConfig from "./commonConfig";

class HrProduct {

  constructor() {
    this.flag=''
    this.proName='';
    this.show=true;
    this.videoShow=true
    this.videoText='使用心得'
    this.videoUrl=''
    this.videoImgUrl=''
    this.productLogoClickUrl=''
    this.notLogin=false
    this.fullNewUserNotShow=false
    this.fullNewUserNotShowClickTip=''
    this.logoImgUrl=''
    this.logoImgStyle=''
    this.productDesc='';

    this.order=0
    this.openWindow=false

    this.goup=0

    this.notRoles=[]
    this.roles=[]
    this.weight=0
  }

}

let homeBase=''
let home_81=commonConfig.home_81
if (home_81){
  homeBase='';
}
if(commonConfig.debug){
  homeBase=""
}




let staticBase=commonConsts.HR_STATIC_BASE+"/video/";
let pro=null;
let productGoups=[]
let products=[]

pro=new HrProduct();
pro.flag='paper'
pro.proName='AI写作助手'
pro.productDesc="实时收录全网Online论文，基于BERT自然语言模型，根据关键词或短语（中/英）自动匹配出语义最相近的英文表述方式。非生命科学领域的同学可\"自建语料库\"。";
pro.productLogoClickUrl=homeBase+"/paper/index.html#/";
pro.videoUrl=[staticBase+'paper0.mp4',staticBase+'paper5_2.mp4',staticBase+'paper7_la.mp4',staticBase+'paper2.mp4',staticBase+'paper3.mp4',staticBase+'paper4.mp4',staticBase+'paper.mp4',staticBase+'paper6_la.mp4']
pro.videoImgUrl=[staticBase+'paper0.png',staticBase+'paper_5_2.png',staticBase+'paper7_la.png',staticBase+'paper2.png',staticBase+'paper3.png',staticBase+'paper4.jpg',staticBase+'paper.jpg',staticBase+'paper6_la.png']
pro.openWindow=true
pro.logoImgUrl='/static/img/svg/new_3_items/paper_log.svg'
pro.goup=0
pro.weight=0
pro.roles=[]
pro.notRoles=[]
products.push(pro)





pro=new HrProduct();
pro.flag='SEARCH_SENTENCE_img'
pro.proName='图例写作工具'
pro.productDesc="图例是文章中figure的说明文字，本工具实时收录在线SCI论文图例数据，输入关键词即可检索相关语料，素材来源可见原文详情，预览图片可见对应图片";
pro.productLogoClickUrl=homeBase+"/paper/index.html#/img_search";
pro.videoUrl=staticBase+'img_paper.mp4'
pro.videoImgUrl=staticBase+'img_paper.jpg'
pro.openWindow=true
pro.logoImgUrl='/static/img/svg/new_3_items/fl_logo.svg'
pro.goup=0
pro.weight=3
pro.roles=[]
pro.notRoles=["vip_month_new"]
products.push(pro)


pro=new HrProduct();
pro.flag='LETTER_SEARCH'
pro.proName='审稿人/回复信'
pro.productDesc="实时收录在线论文回复信中“一问一答”，选择以审稿人或回复者两种角色，输入关键词，可以获得对应语料及其上下文语境，可提高审稿信和回复信的写作效率。";
pro.productLogoClickUrl=homeBase+"/paper/index.html#/letter_search";
pro.videoUrl=staticBase+'letter_search.mp4'
pro.videoImgUrl=staticBase+'letter_search.jpg'
pro.openWindow=true
pro.logoImgUrl='/static/img/svg/new_3_items/rev_rep_logo.svg'
pro.goup=0
pro.weight=4
pro.roles=[]
pro.notRoles=[]
products.push(pro)


pro=new HrProduct();
pro.proName='场景写作工具'
pro.flag='ACA_SEARCH'
pro.productDesc="学术论文有格式化的特点，本工具收录了写作中会遇到的场景及对应表述方式。注意,场景顺序也是写作的逻辑顺序。和AI写作助手协同使用将进一步提高写作效率。";
pro.productLogoClickUrl=homeBase+"/paper/index.html#/aca_search";

pro.videoUrl=[staticBase+'aca_search.mp4',staticBase+'aca_search1.mp4']
pro.videoImgUrl=[staticBase+'aca_search.jpg',staticBase+'aca_search1.png']

pro.openWindow=true
pro.logoImgUrl='/static/img/svg/new_3_items/aca_search_logo.svg'
pro.goup=0
pro.weight=4
pro.roles=[]
pro.notRoles=[]
products.push(pro)

pro=new HrProduct();
pro.flag='SEARCH_SENTENCE_review'
pro.proName='综述Review'
pro.productDesc="综述（Review）是对前人研究的总结概括，能提出新的见解更佳，科研初学者可多多尝试。本工具实时收录online的Review文献，提取并归类语料。";
pro.productLogoClickUrl=homeBase+"/paper/index.html#/review_search";
pro.videoUrl=staticBase+'review_search.mp4'
pro.videoImgUrl=staticBase+'review_search.jpg'
pro.openWindow=true
pro.logoImgUrl='/static/img/svg/new_3_items/review_logo.svg'
pro.goup=0
pro.weight=3
pro.roles=[]
pro.notRoles=["vip_month_new"]
products.push(pro)




pro=new HrProduct();
pro.flag='SEARCH_SENTENCE_report'
pro.proName='个案报道Case'
pro.productDesc="个案报道（Casereport）是对临床疑难，少见病例的高度总结，NEJM，JMAM等顶级期刊均接收。本工具实时收录online的Casereport文献，提取并归类语料。";
pro.productLogoClickUrl=homeBase+"/paper/index.html#/report_search";
pro.videoUrl=staticBase+'report_search.mp4'
pro.videoImgUrl=staticBase+'report_search.jpg'
pro.openWindow=true
pro.logoImgUrl='/static/img/svg/new_3_items/report_logo.svg'
pro.goup=0
pro.weight=3
pro.roles=[]
pro.notRoles=["vip_month_new"]
products.push(pro)




productGoups.push(products)


products=[]

pro=new HrProduct();
pro.flag='PROJECT_ASSISTANT'
pro.proName='课题思路助手'
pro.productDesc="基础科研有三要素：基因、信号通路、表型/功能。这里，输入关键词，即可从海量文献中检索与之相关的gene、pathway，按年度分析可尽览其“研究演变”。";
pro.productLogoClickUrl=homeBase+"/static/index.html#/project_assistant";
pro.videoUrl=staticBase+'project_assistant.mp4'
pro.videoImgUrl=staticBase+'project_assistant.png'
pro.logoImgUrl='/static/img/svg/project_assistant.svg'
pro.logoImgStyle="width:4.11rem;"
pro.goup=1
pro.weight=0
pro.roles=[]
pro.notRoles=[]
products.push(pro)

pro=new HrProduct();
pro.proName='国自然数据分析'
pro.flag='FUND_TEST'
pro.productDesc="本系统收录近30年（1989-2019）国家自然科学基金立项标书关键信息，结合多重整合分析，数据图形化展示，历年研究热点和立项标书特点一目了然。";
pro.productLogoClickUrl=homeBase+"/static/index.html#/funding";
pro.videoUrl=staticBase+'fund.mp4'
pro.videoImgUrl=staticBase+'fund.jpg'
pro.logoImgUrl='/static/img/svg/fund_log.svg'
pro.goup=1
pro.weight=4
pro.roles=[]
pro.notRoles=[]
products.push(pro)


pro=new HrProduct();
pro.flag='CHECK_ARTICLE'
pro.proName='查重自助提交系统'
pro.productDesc="HR获得了iThenticate官方的渠道授权，我们以目前市场价20%的 <span style=\"font-weight: 900;color:rgb(57,126,195);\">“成本价”</span>给大家提供Crosscheck查重报告。自助提交，系统自动计算字数后运行，自助下载。";
pro.productLogoClickUrl=homeBase+"/static/index.html#/check_article";
pro.videoShow=false
pro.videoText="无需演示"
pro.logoImgUrl='/static/img/svg/new_3_items/check_article_logo.svg'
pro.goup=1
pro.weight=0
pro.roles=[]
pro.notRoles=[]
products.push(pro)




productGoups.push(products)



products=[]
pro=new HrProduct();
pro.flag='IFSEARCH'
pro.proName='期刊查询'
pro.productDesc="共收录12038本期刊的近5年影响因子走势、中科院/jcr分区，历年发表量、国人占比、自引率等重要信息，评论区为投稿经验交流。";
pro.productLogoClickUrl=homeBase+"/static/index.html#/ifsearch";
pro.videoUrl=staticBase+'ifsearch.mp4'
pro.videoImgUrl=staticBase+'ifsearch.jpg'
pro.logoImgUrl='/static/img/svg/new_3_items/scilogo.svg'
pro.goup=2
pro.weight=4
pro.roles=[]
pro.notRoles=[]
products.push(pro)


pro=new HrProduct();
pro.flag='IFSEARCH'
pro.proName='生信之友'
pro.productDesc="生信因为可以“不做实验”发表article而受到喜爱。接收生信的期刊较为集中，这个工具方便大家能够实时掌握生信可投期刊及其关键信息。";
pro.productLogoClickUrl=homeBase+"/static/index.html#/bi_ifsearch";
pro.videoUrl=staticBase+'bi_ifsearch.mp4'
pro.videoImgUrl=staticBase+'bi_ifsearch.jpg'
pro.logoImgUrl='/static/img/svg/new_3_items/sci_bi_logo.svg'
pro.goup=2
pro.weight=4
pro.roles=[]
pro.notRoles=[]
products.push(pro)


pro=new HrProduct();
pro.flag='IFSEARCH'
pro.proName='国刊之光'
pro.productDesc="为响应近日科技部措施，鼓励发表高质量国内期刊论文，这里动态收录了具有国际影响力（影响因子）的国内科技期刊，包含期刊介绍及投稿链接。";
pro.productLogoClickUrl=homeBase+"/static/index.html#/china_ifsearch";
pro.videoShow=false
pro.videoText="无需演示"
pro.logoImgUrl='/static/img/svg/new_3_items/sci_china_logo.svg'
pro.goup=2
pro.weight=4
pro.roles=[]
pro.notRoles=[]
products.push(pro)



//权重 未知
pro=new HrProduct();
pro.flag='IFSEARCH_TYPES'
pro.proName='“第一篇”选刊'
pro.productDesc="Review和Letter是初次写作的科研者最适合拿来练手的“第一篇”，这里动态收录了所有期刊自2015年以来，这两种类型的发表数量，以供选刊参考。";
pro.productLogoClickUrl=homeBase+"/static/index.html#/if_search_types";
pro.videoShow=false
pro.videoText="无需演示"
pro.logoImgUrl='/static/img/svg/new_3_items/ifsearch_types_logo.svg'
pro.goup=2
pro.weight=4
pro.roles=[]
pro.notRoles=[]
products.push(pro)


pro=new HrProduct();
pro.flag='IF_PREDICTION'
pro.proName='2020年六月最新IF'
pro.productDesc="每年6月，SCI官方科睿唯安会发布期刊新一年度的影响因子，注意，20年发布的是19年的IF，这里提供简明的IF波动变化，包括特殊点位如3分，5分的突破或跌破。";
pro.productLogoClickUrl="http://2020if.home-for-researchers.com/static/index.html#/";
pro.notLogin=true
pro.logoImgUrl='/static/img/ifsearch/if_prediction_home_.svg'
pro.goup=2
pro.weight=0
pro.roles=[]
pro.notRoles=[]
pro.videoShow=false
pro.videoText="无需演示"
products.push(pro)



pro=new HrProduct();
pro.flag='PAPER_CHOOSE'
pro.proName='选刊助手'
pro.productDesc="选刊除了要考虑期刊本身影响因子等信息外，Scope务必要相符。这个工具通过和期刊历年文章的Title，Keywords或Abstract比对，获得期刊综合匹配指数。";
pro.productLogoClickUrl=homeBase+"/static/index.html#/paper_choose";
pro.videoUrl=staticBase+'paper_choose.mp4'
pro.videoImgUrl=staticBase+'paper_choose.jpg'
pro.logoImgUrl='/static/img/svg/paper_choose_logo.svg'
pro.goup=2
pro.weight=0
pro.roles=[]
pro.notRoles=[]
pro.videoShow=false
pro.videoText="无需演示"
products.push(pro)


productGoups.push(products)
products=[]



pro=new HrProduct();
pro.flag='STATISTICS_COMPARE_GOUP'
pro.proName='两组比较'
pro.productDesc="“两组比较”有特定的统计学方法，根据是连续变量(如血压)还是计数变量(如个数),以及不同应用场景又采用不同方法。替换示例数据为自己的数据即可获得统计结果，支持6种顶刊风格的矢量图下载。";
pro.productLogoClickUrl=homeBase+"/static/index.html#/statistics/metering";
pro.videoUrl=staticBase+'statistics_goup_compare.mp4'
pro.videoImgUrl=staticBase+'statistics_goup_compare.jpg'
pro.logoImgUrl='/static/img/svg/group_compare.svg'
pro.goup=3
pro.weight=0
pro.roles=[]
pro.notRoles=[]
products.push(pro)





pro=new HrProduct();
pro.flag='STATISTICS_COMPARE_GOUP'
pro.proName='≥3组比较'
pro.productDesc="组间比较中，除了“两组比较”，≥3组的数据间比较同样有特定的统计学方法，根据数据类型和应用场景不同而采用不同方法。计量资料又根据是否采用了分组而不同。同样支持6种矢量图下载。";
pro.productLogoClickUrl=homeBase+"/static/index.html#/statistics/metering_more";
pro.videoUrl=staticBase+'statistics_goup_compare_more.mp4'
pro.videoImgUrl=staticBase+'statistics_goup_compare_more.jpg'
pro.logoImgUrl='/static/img/svg/group_compare_more.svg'
pro.goup=3
pro.weight=0
pro.roles=[]
pro.notRoles=[]
products.push(pro)


pro=new HrProduct();
pro.flag='STATISTICS_COMPARE_GOUP'
pro.proName='生存分析'
pro.productDesc="生存分析是专门针对生存数据的统计方法，有两种场景，一是评估接受了不同干预措施病人预后的差异；二是对病人预后的危险因素的分析。支持作图、6种顶刊风格的PDF矢量图下载，方便AI修改。";
pro.productLogoClickUrl=homeBase+"/static/index.html#/statistics/analysis_live";
pro.videoUrl=staticBase+'analysis_live.mp4'
pro.videoImgUrl=staticBase+'analysis_live.jpg'
pro.logoImgUrl='/static/img/svg/analysis_live.svg'
pro.goup=3
pro.weight=0
pro.roles=[]
pro.notRoles=[]
products.push(pro)


pro=new HrProduct();
pro.proName='相关性分析'
pro.flag='STATISTICS_COMPARE_GOUP'
pro.productDesc="想明确x和y之间的关系程度和变化方向（相同趋势或相反趋势），叫做相关性分析，是危险因素分析（线性回归）的前提，不进行相关性分析而直接进行危险因素分析而得出的回归方程，很容易产生“虚假回归”。";
pro.productLogoClickUrl=homeBase+"/static/index.html#/statistics/analysis_relate";
pro.videoUrl=staticBase+'analysis_relate.mp4'
pro.videoImgUrl=staticBase+'analysis_relate.jpg'
pro.logoImgUrl='/static/img/svg/analysis_relate.svg'
pro.goup=3
pro.weight=0
pro.roles=[]
pro.notRoles=[]
products.push(pro)


pro=new HrProduct();
pro.proName='危险因素分析'
pro.flag='STATISTICS_COMPARE_GOUP'
pro.productDesc="在相关性分析获得关系程度和方向的基础上，进一步得到一个x和y的回归方程式，即通过x可以得到y值。根据数据类型不同，又采用不同统计学方法。计量资料又根据是多个因素还是多个因素而不同。";
pro.productLogoClickUrl=homeBase+"/static/index.html#/statistics/analysis_risk";
pro.videoUrl=staticBase+'analysis_risk.mp4'
pro.videoImgUrl=staticBase+'analysis_risk.jpg'
pro.logoImgUrl='/static/img/svg/analysis_risk.svg'
pro.goup=3
pro.weight=0
pro.roles=[]
pro.notRoles=[]
products.push(pro)


pro=new HrProduct();
pro.proName='诊断性分析'
pro.flag='STATISTICS_COMPARE_GOUP'
pro.productDesc="研究一些潜在的Biomarker对疾病诊断是否有效，可以使用诊断性分析，准确度和敏感度是诊断指标最重要的两个指标，可以绘制ROC曲线，通过曲线下面积AUC来综合比较不同诊断指标的优劣。";
pro.productLogoClickUrl=homeBase+"/static/index.html#/statistics/analysis_diagnose";
pro.videoUrl=staticBase+'analysis_diagnose.mp4'
pro.videoImgUrl=staticBase+'analysis_diagnose.jpg'
pro.logoImgUrl='/static/img/svg/analysis_diagnose.svg'
pro.goup=3
pro.weight=0
pro.roles=[]
pro.notRoles=[]
products.push(pro)
productGoups.push(products)




products=[]




// pro=new HrProduct();
// pro.proName='每日福利'
// pro.flag='HR_GIFT'
// pro.productDesc="熬夜工作似乎是科研者的常态，每个午夜零点，这里开放小福利的领取，我们免费包邮送给大家，希望能给在夜深人静努力的你一丝丝温暖。";
// pro.productLogoClickUrl="/#/hr_gift";
// pro.videoUrl=staticBase+'fund.mp4'
// pro.videoImgUrl=staticBase+'fund.jpg'
// pro.logoImgUrl='/static/img/svg/new_3_items/gift_logo_2.svg'
// pro.videoShow=false
// pro.videoText="无需演示"
// pro.fullNewUserNotShow=false
// pro.fullNewUserNotShowClickTip='抱歉，新用户福利开启还需时间哦，过几天再试试吧'
// pro.goup=4
// pro.weight=3
// pro.roles=[]
// pro.notRoles=[]
// products.push(pro)


// pro=new HrProduct();
// pro.flag='PROJECT_ASSISTANT'
// pro.proName='神器建设中...'
// pro.productDesc="";
// pro.productLogoClickUrl="";
// pro.videoUrl=staticBase+'project_assistant.mp4'
// pro.videoImgUrl=staticBase+'project_assistant.jpg'
// pro.logoImgUrl='/static/img/svg/home_log.svg'
// pro.goup=4
// pro.weight=0
// pro.roles=[]
// pro.notRoles=[]
// pro.videoShow=false
// pro.videoText="无需演示"
// products.push(pro)



pro=new HrProduct();
pro.proName='科研日历'
pro.flag='HR_CALENDAR'
pro.productDesc="科研日历由Home for Researchers用心打造，由数十位科研一线的师兄师姐分享，每天一个科研小知识，伴你成长。";
pro.productLogoClickUrl="/static/index.html#/calendar";
pro.videoUrl=staticBase+'fund.mp4'
pro.videoImgUrl=staticBase+'fund.jpg'
pro.logoImgUrl='/static/img/svg/new_3_items/hr_rili.png'
pro.videoShow=false
pro.videoText="2021年度"
pro.fullNewUserNotShow=true
pro.fullNewUserNotShowClickTip='抱歉，科研日历开启还需时间哦，过几天再试试吧'
pro.goup=4
pro.weight=0
pro.roles=[]
pro.notRoles=[]
products.push(pro)




pro=new HrProduct();
pro.proName='临床生信之家'
pro.flag='HR_RESEARCH'
pro.productDesc="对R语言进行了可视化处理，无需代码基础，在线通过鼠标点击即可完成包括差异基因、免疫检查点，突变全景等生信分析，是科研者之家旗下的生信分析平台。";
pro.productLogoClickUrl="https://www.aclbi.com/";
pro.videoUrl=staticBase+'fund.mp4'
pro.videoImgUrl=staticBase+'fund.jpg'
pro.logoImgUrl='/static/img/svg/new_3_items/logo_img_search.jpg'
pro.videoShow=false
pro.videoText="无需演示"
pro.notLogin=true
pro.alter='即将离开Home for Researchers，前往临床生信之家，确定？'
// pro.fullNewUserNotShow=true
// pro.fullNewUserNotShowClickTip='抱歉，科研日历开启还需时间哦，过几天再试试吧'

pro.goup=4
pro.weight=0
pro.roles=[]
pro.notRoles=[]
products.push(pro)

pro=new HrProduct();
pro.flag='PROJECT_ASSISTANT'
pro.proName='神器建设中...'
pro.productDesc="";
pro.productLogoClickUrl="";
pro.videoUrl=staticBase+'project_assistant.mp4'
pro.videoImgUrl=staticBase+'project_assistant.jpg'
pro.logoImgUrl='/static/img/svg/home_log.svg'
pro.goup=4
pro.weight=0
pro.roles=[]
pro.notRoles=[]
pro.videoShow=false
pro.videoText="无需演示"
products.push(pro)

productGoups.push(products)


export default {

  HR_PRODUCT_GROUPS:productGoups

}
