import {StatisticsNav} from "./commonClass";
import {StatisticsProduct} from "./commonClass";
import {StatisticsMethod} from "./commonClass";
// import commonUtils from "./commonUtils";


let metering=new StatisticsProduct()
let metering_more=new StatisticsProduct()
let analysis_live=new StatisticsProduct()
let analysis_relate=new StatisticsProduct()
let analysis_risk=new StatisticsProduct()
let analysis_diagnose=new StatisticsProduct()

let statisticsNavMap={
    "metering":metering,
    "metering_more":metering_more,
    "analysis_live":analysis_live,
    "analysis_relate":analysis_relate,
    "analysis_risk":analysis_risk,
    "analysis_diagnose":analysis_diagnose
}

let INNER_ERROR_MSG_FIELD="__err_msg__"

let NOT_DNA_INFO_MAP={
    'C1orf20':'1',
    'C1orf136':'1',
    'C1orf193':'1',
    'C1orf197':'1',
    'C3orf53':'1',
    'C5orf9':'1',
    'C6orf36':'1',
    'C6orf50':'1',
    'C6orf202':'1',
    'C6orf215':'1',
    'C8orf6':'1',
    'C8orf15':'1',
    'C8orf16':'1',
    'C9orf33':'1',
    'C9orf38':'1',
    'C9orf56':'1',
    'C9orf63':'1',
    'C9orf149':'1',
    'C11orf26':'1',
    'C13orf41':'1',
    'C14orf7':'1',
    'C14orf16':'1',
    'C14orf40':'1',
    'C14orf57':'1',
    'C14orf65':'1',
    'C14orf113':'1',
    'C14orf136':'1',
    'C14orf157':'1',
    'C14orf184':'1',
    'C15orf31':'1',
    'C15orf49':'1',
    'C16orf43':'1',
    'C17orf11':'1',
    'C17orf84':'1',
    'C18orf41':'1',
    'C18orf61':'1',
    'C19orf8':'1',
    'C19orf31':'1',
    'C20orf66':'1',
    'C20orf109':'1',
    'C21orf32':'1',
    'C21orf72':'1',
    'C21orf79':'1',
    'C21orf86':'1',
    'C21orf114':'1',
    'CLDN11CL':'1',
    'COLOT1':'1',
    'DDX7':'1',
    'DEFA8':'1',
    'EEF1A1L14':'1',
    'FAM22C':'1',
    'FAM41BY':'1',
    'FAM231C':'1',
    'FLYWCH1L1':'1',
    'FOLR2L':'1',
    'GPB':'1',
    'GPR90':'1',
    'GPR130':'1',
    'GTA':'1',
    'GVQW1':'1',
    'HSPA3':'1',
    'LENG6':'1',
    'LENG10':'1',
    'LENG11':'1',
    'M195':'1',
    'MELL1':'1',
    'MSNL2':'1',
    'OCLM':'1',
    'PIK3R1OS':'1',
    'PPARAL':'1',
    'PRAMEF3':'1',
    'PRAMEF16':'1',
    'SLC4A7OS':'1',
    'SMCR9':'1',
    'SPHAR':'1',
    'TGFBRE':'1',
    'TMEM137':'1',
    'XRCC6BP2':'1',
    'ZNF1':'1',
    'ZNF43L':'1',
    'ZNF116':'1',
    'ZNF125':'1',
    'ZNF126':'1',
    'ZNF164':'1',
    'ZNF171':'1',
    'ZNF172':'1',
    'ZNF182L':'1',
    'ZNF188':'1',
    'ZNF194':'1',
    'ZNF196':'1',
    'ZNF199':'1',
    'ZNF240':'1',
    'ZNF241':'1',
    'ZNF242':'1',
    'ZNF244':'1',
    'ZNF245':'1',
    'ZNF246':'1',
    'ZNF247':'1',
    'ZNF249':'1',
    'ZNF301':'1',
    'ZNF522':'1'
}


let MYSENTENCE_LIMIT_COMMON_ERROR='              <div style="border-collapse:collapse;text-align: center;font-size: 20px;font-weight: 600;margin-bottom: 21px">抱歉，自建语料库功能需要以下权限</div>\n' +
'\n' +
'              <div style="text-align: center">\n' +
'                              <table style="\n' +
'border: 1px solid #D8D8D8;width: 320px;height: 143px;margin: 0 auto;font-weight: 500;font-size: 15px;">\n' +
'                <tr style="background-color: #F4F4F4;">\n' +
'                  <th style="border-right: 1px solid  #D8D8D8;"></th>\n' +
'                  <th style="transform: translateY(3px);border-right: 1px solid  #D8D8D8;">年费会员</th>\n' +
'                   <th style="transform: translateY(3px);">荣誉会员</th>\n' +
'                </tr>\n' +
'                <tr >\n' +
'                  <td style="transform: translateY(3px); border-right: 1px solid  #D8D8D8;">文献数量</td>\n' +
'                  <td style="transform: translateY(3px);border-right: 1px solid  #D8D8D8;">&lt;100</td>\n' +
'                   <td style="transform: translateY(3px);">&lt;10000</td>\n' +
'                </tr>\n' +
'                <tr style="background-color: #F4F4F4">\n' +
'                   <td style="transform: translateY(3px);border-right: 1px solid  #D8D8D8;">单个文件大小</td>\n' +
'                    <td style="transform: translateY(3px);border-right: 1px solid  #D8D8D8;">&lt;10M</td>\n' +
'                   <td style="transform: translateY(3px);">&lt;50M</td>\n' +
'                </tr>\n' +
'                <tr>\n' +
'                   <td style="transform: translateY(3px);border-right: 1px solid  #D8D8D8;">收藏语料</td>\n' +
'                    <td style="transform: translateY(3px);border-right: 1px solid  #D8D8D8;">&lt;500条</td>\n' +
'                   <td style="transform: translateY(3px);">&lt;10万条</td>\n' +
'                </tr>\n' +
    '                <tr style="background-color: #F4F4F4">\n' +
    '                   <td style="transform: translateY(3px);border-right: 1px solid  #D8D8D8;">检索次数</td>\n' +
    '                    <td style="transform: translateY(3px);border-right: 1px solid  #D8D8D8;">不限</td>\n' +
    '                   <td style="transform: translateY(3px);">不限</td>\n' +
    '                </tr>\n' +

    '                <tr >\n' +
    '                   <td style="transform: translateY(3px);border-right: 1px solid  #D8D8D8;">团队语料</td>\n' +
    '                    <td style="transform: translateY(3px);border-right: 1px solid  #D8D8D8;">不能共享</td>\n' +
    '                   <td style="transform: translateY(3px);">可共享</td>\n' +
    '                </tr>\n' +


    '                <tr style="background-color: #F4F4F4">\n' +
    '                   <td style="transform: translateY(3px);border-right: 1px solid  #D8D8D8;">他人自建语料</td>\n' +
    '                    <td style="transform: translateY(3px);border-right: 1px solid  #D8D8D8;">无法检索</td>\n' +
    '                   <td style="transform: translateY(3px);">可检索</td>\n' +
    '                </tr>\n' +

'              </table>\n' +
'              </div>'

export default {

    TEXT_AEAR_TYPE_KEY:'TEXT_AEAR_TYPE_KEY',

    HR_STATIC_BASE:'https://static.home-for-researchers.com',
    NOT_LOGIN:'请从官网 <a href="/">www.home-for-researchers.com</a>登陆工具，谢谢<br>其他可能原因：1.未使用谷歌内核浏览器。2.账号被他人占用。3.不同运营商的网络切换，重新登录即可 4.关闭开启的VPN工具\'\n',
    NOT_VIP_COUNT:'抱歉，今天的使用次数已经用完，请明日再来。马上升级权限？',
    BLACK_USER:'抱歉，过去一段时间内，您的操作次数过于频繁，如再次触发，账户将被封禁，请规范使用工具，谢谢配合。如为误报，请联系工作人员微信：Home4R',
    HOME_STATIC_URL_BASE:'https://static.home-for-researchers.com/',
    COMMON_RES_ERROR:'网络错误，请强制刷新浏览器，确保登录状态重试',

    RESEARCH_INIT_DNA_DIFF_COUNT:3,
    RESEARCH_INIT_DOWN_COUNT:3,
    RESEARCH_INIT_CALC_COUNT:10,
    RESEARCH_PAY_URL:'/#/research_pay',
    RESEARCH_NOT_VIP_COUNT:'抱歉，你的plot次数已用完，升级权限？',
    RESEARCH_NOT_LOGIN:'请从官网 <a href="https://www.aclbi.com/static/index.html">www.aclbi.com</a>登陆工具，谢谢<br>其他可能原因：1.未使用谷歌内核浏览器。2.账号被他人占用。3.不同运营商的网络切换，重新登录即可 4.关闭开启的VPN工具\'\n',

    MYSENTENCE_LIMIT_COMMON_ERROR,
    MYSETENCE_UP_FILE_SIZE_ERROR:'文件大小超限，请确保文件大小不超过限制',
    MYSETENCE_UP_FILE_COUNT_ERROR:'文件个数超限，请确保文件个数不超过限制。提升等级获得更多空间？',
    MYSETENCE_UP_FILE_NIUION_ERROR:'文件重复上传',
    PAPER_CHOOSE_YEAR_VIP_TIP:'抱歉，该功能为荣誉会员专享,年费会员可通过转发支持向工作人员免费申请开通',

    LEVEL_NOT_ENABLE:'抱歉，该功能暂时只对更高级别会员开放，马上升级？',

    CHECK_ARTICLE_UPLOAD_PRE_MONEY_URL:'/protect/check_article/pre_monney_upload',
    CHECK_ARTICLE_UPLOAD_URL:'/protect/check_article/upload',
    STATIC_SENTENCE_IMG_URL_BASE:'https://static.home-for-researchers.com/sentence_img',
    STATISTICS_IMAGE_STATIC_URL_BASE:'http://statistics.home-for-researchers.com:5000',

    COMMON_PAY_URL:'/paper/index.html#/pay',

    HOME_BASE_URL_PATH:'/static/index.html#/',
    // HOME_BASE_URL_PATH:'/static/index_figcheck.html#/',
    // HOME_BASE_URL_PATH:'/static/index_medreading.html#/',

    COMMON_SPEED_OVER_ERROR:'大侠，你手速太快了，稍等会再计算',
    COMMON_STATISTICS_NOT_CALC_COUNT:'计算次数已经使用完，请升级权限',
    COMMON_CHECK_ARTICLE_NOT_COUNT:'查重次数超过限制，请明日再来',

    SIGNAL_PATH_OPEN_URL_BASE:'https://www.kegg.jp',
    NOT_DNA_INFO_MAP,
    DNA_OPEN_URL_BASE:'https://www.genecards.org',


    FEEDBACK_NOT_USER_INFO:'本论坛为匿名，你目前没有留下联系方式，我们将无法联系到你，确定提交？',

    PRODUCT_NOT_OEPN:'抱歉，新同学还不能使用该模块',

    PAPER_INPUT_MAX_COUNT:100,

    statisticsNavMap,INNER_ERROR_MSG_FIELD,



    SENTENCE_PDF_UP_URL_BASE:'',


    RESEARCH_ASSISSTATIE_BASE:'/static/',
    RESEARCH_ASSISSTATIE_ERROR:{
        "e001":'根据要求选择的样本个数为空，请重新调整临床变量',
        "e002":'该基因在50%以上的样本表达量均为0,请更换基因',
        "e004": "差异基因个数为0，请降低过滤阈值",
        "e005": "两组肿瘤样本存在交集，请重新选择样本"
    },

    SAID_IMG_PUBLIC_STATIC:'http://said-static-public.oss-cn-shenzhen.aliyuncs.com/',

    HR_HOME_URL(){
        return 'https://www.home-for-researchers.com/static/index.html#/'
    }
}









let tempNav=null;
// let productTemp=null;
let tempMethod=null;


//产品

metering.chinaName="两组比较";
metering.name='metering'
metering.logoClickRoutePath="/statistics/metering"
metering.topLogoText="两组比较"
metering.siteStatisticsId='20766039'

//产品方法和导航
tempNav=new StatisticsNav();
tempNav.title="计量资料";
tempNav.isTitle=true;
metering.navs.push(tempNav);



//
tempNav=new StatisticsNav();
tempMethod=new StatisticsMethod();
tempMethod.chinaName="独立样本t检验"
tempMethod.name='tTest'
tempMethod.title="某研究者欲研究男性和女性的体重差异。随机选取5例男性和5例女性,分别称体重如下（单位：kg）,A列为男性,B列为女性。"
tempMethod.intrPlist=["研究目的：研究男性和女性的体重均数是否存在差异。","因变量：体重，连续变量","自变量：性别，分类变量，二分类","统计分析方法：两独立样本均数t检验"]
tempMethod.exampleThs=["序号",	"第一组 (A列)",	"第二组 (B列)"]
tempMethod.exampleRows=[[1,55,50],[2,65,48],[3,60,45],[4,58,51],[5,63,49]]
tempMethod.initTableRows=[[55,50],[65,48],[60,45],[58,51],[63,49]]
tempMethod.colLimit=2
tempMethod.apiField='t_test'
tempMethod.hasImage=true

tempMethod.imageBnOptions.rightShow=true

tempMethod.getReqData=(inputCols)=>{
    let data={
        list1:inputCols[0],
        list2:inputCols[1]
    }
    return data;
}

tempMethod.imageBnOptions.leftShow=false;

tempMethod.initResData='{"msg":"success","code":200,"data":[[60.2,48.6],[3.9623,2.3022],[5,5],[0.9453],[0.6853],[0.3179],[5.6602],[0.001],[5.6602],[5.0E-4],["30a8dc2a-cea1-11ea-98ec-0242c0a82008.pdf"],[]]}';
tempNav.title="独立样本t检验";
tempNav.isTitle=false;
tempMethod.imageBnOptions.pdfBlockStyle="height:5.6rem;"
tempNav.active=true;
tempNav.method=tempMethod
metering.navs.push(tempNav);



//
tempNav=new StatisticsNav();
tempMethod=new StatisticsMethod();
tempMethod.chinaName="配对样本t检验"
tempMethod.name='paired_ttest'
tempMethod.title="研究运动对减肥的效果。对5名成年人进行运动指导，分别测量运动前和运动后体重（单位：kg），A列是运动前体重，B列是运动后体重。"
tempMethod.intrPlist=["研究目的：研究运动是否可以减肥。","变量：体重，连续变量","统计分析方法：配对t检验。配对t检验是单样本t检验的特殊情况，配对设计是指同质受试对象配成对子，分别接受两种不同的处理，或同一受试对象分别接受两种不同处理。在单样本t检验中，总体标准差未知且样本含量较小，要求样本来自正态分布总体。"]
tempMethod.exampleThs=["序号",	"第一组 (A列)",	"第二组 (B列)"]
tempMethod.exampleRows=[[1,55,50],[2,65,48],[3,60,45],[4,58,51],[5,63,49]]
tempMethod.initTableRows=[[55,50],[65,48],[60,45],[58,51],[63,49]]
tempMethod.colLimit=2
tempMethod.apiField='paired_ttest'
tempMethod.hasImage=true
tempMethod.initResData="{\"msg\":\"success\",\"code\":200,\"data\":[[11.6],[5.2726],[5],[0.321],[4.9195],[0.0079],[\"d3b55d94-ceab-11ea-98ec-0242c0a82008.pdf\"],[]]}"
tempMethod.imageBnOptions.rightShow=true

tempMethod.getReqData=(inputCols)=>{

    try {

        if(inputCols[0].length!=inputCols[1].length){
            let err={}
            err[INNER_ERROR_MSG_FIELD]='配对样本T检验，第一组数据和第二组数据的长度需 要相等'
            return err
        }
        // eslint-disable-next-line no-empty
    }catch (e) {

    }

    let data={
        list1:inputCols[0],
        list2:inputCols[1]
    }



    return data;
}



tempNav.title="配对样本t检验";
tempNav.isTitle=false;
tempMethod.imageBnOptions.pdfBlockStyle="height:5.6rem;"
tempNav.method=tempMethod
metering.navs.push(tempNav);

//

tempNav=new StatisticsNav();
tempNav.title="计数资料";
tempNav.isTitle=true;
metering.navs.push(tempNav);


//

tempNav=new StatisticsNav();
tempMethod=new StatisticsMethod();
tempMethod.chinaName="等级资料U检验"
tempMethod.name='U_test'
tempMethod.title="某研究者欲研究不同药物对支气管哮喘的患者的疗效。 患者随机分为二组，分别为激素组（试验组A列）、中药组（对照组B列）。+、++、+++、++++分别代表无效、有效、显效、治愈。"
tempMethod.intrPlist=["研究目的：研究不同药物对支气管哮喘的患者的疗效是否有差异。","因变量：疗效（等级资料）","自变量：不同药物（分类变量，二分类）","统计分析方法：等级资料的U检验"]
tempMethod.exampleThs=["组别",	"试验组 (A列)",	"对照组 (B列)","合计"]
tempMethod.exampleRows=[["+",1,8,9],["++",5,2,7],["+++",16,27,43],["++++",35,18,53],["合计",57,55,112]]
tempMethod.initTableRows=[[1,8],[5,2],[16,27],[35,18]]
tempMethod.colLimit=2
tempMethod.apiField='U_test'
tempMethod.initResData="{\"msg\":\"success\",\"code\":200,\"data\":[[2035],[0.003],[64.7018,48]]}"
tempMethod.getReqData=(inputCols)=>{

    let data={
        list1:inputCols[0],
        list2:inputCols[1]
    }
    return data;
}


tempNav.title="等级资料U检验";
tempNav.isTitle=false;
tempNav.method=tempMethod
metering.navs.push(tempNav);




//
tempNav=new StatisticsNav();
tempMethod=new StatisticsMethod();
tempMethod.chinaName="四格表卡方检验"
tempMethod.name='chi_test'
tempMethod.title="某研究者欲比较血塞通注射液和银杏达莫注射液治疗急性脑梗死的效果。将240例急性脑梗死患者随机分成2组，一组给予血塞通注射液治疗（组1 A列），另一组给予银杏达莫注射液治疗（组2 B列），一个疗程后观察结果，问两种针剂治疗急性脑梗死的有效率是否有差别？\n"
tempMethod.intrPlist=["研究目的：研究两种针剂治疗急性脑梗死的有效率是否有差别。","因变量：是否有效（分类变量，二分类）","自变量：针剂种类（分类变量，二分类）","统计分析方法：四格表卡方检验"]
tempMethod.exampleThs=["组别",	"有效",	"无效","合计"]
tempMethod.exampleRows=[["组1 (A列)","114(a)","6(b)","120"],["组2 (B列)","104(c)","16(d)","120"],["合计",218,22,240]]
tempMethod.initTableRows=[[114,6],[104,16]]
tempMethod.colLimit=2
tempMethod.rowLimit=2
tempMethod.tableHeight="2rem"
tempMethod.apiField="chi_test"
tempMethod.initResData="{\"msg\":\"success\",\"code\":200,\"data\":[[240],[[109,11],[109,11]],[5.0042],[0.0253]]}";
tempMethod.getReqData=(inputCols)=>{

    let list=[...inputCols[0]]
    try {
        list=list.concat(...inputCols[1])
        // eslint-disable-next-line no-empty
    }catch (e) {

    }

    let data={
        list
    }
    return data;
}



tempNav.title="四格表卡方检验";
tempNav.isTitle=false;
tempNav.method=tempMethod
metering.navs.push(tempNav);


//
tempNav=new StatisticsNav();
tempMethod=new StatisticsMethod();
tempMethod.chinaName="配对卡方检验"
tempMethod.name='paired_chi_test'
tempMethod.title="某研究者欲比较心电图（方法1）和生化测定（方法2）低钾血症的价值。分别采用两种方法对79名临床确诊的低钾血症患者进行检查，结果如下表。问两种方法的检出阳性率是否不同？"
tempMethod.intrPlist=["研究目的：研究两种方法的检出阳性率是否不同","因变量：是否低钾血症（分类变量，二分类）","自变量：方法（分类变量，二分类）","统计分析方法：配对样本卡方检验"]
tempMethod.exampleThs=["方法1\\方法2",	"+",	"-","合计"]
tempMethod.exampleRows=[["+",45,25,70],["-",4,5,9],["合计",49,30,79]]
tempMethod.initTableRows=[[45,25],[4,5]]
tempMethod.colLimit=2
tempMethod.rowLimit=2
tempMethod.tableHeight="2rem"
tempMethod.apiField="paired_chi_test"
tempMethod.initResData="{\"msg\":\"success\",\"code\":200,\"data\":[[79],[13.7931],[2.0E-4]]}";
tempMethod.getReqData=(inputCols)=>{

    let list=[...inputCols[0]]
    try {
        list=list.concat(...inputCols[1])
        // eslint-disable-next-line no-empty
    }catch (e) {

    }

    let data={
        list
    }
    return data;
}

tempNav.title="配对卡方检验";
tempNav.isTitle=false;
tempNav.method=tempMethod
metering.navs.push(tempNav);





//产品
metering_more.chinaName="≥3组比较";
metering_more.name='metering_more'
metering_more.logoClickRoutePath="/statistics/metering_more"
metering_more.topLogoText="组间比较（≥3组）"
metering_more.siteStatisticsId='20768263'


//产品方法和导航
tempNav=new StatisticsNav();
tempNav.title="计量资料";
tempNav.isTitle=true;
metering_more.navs.push(tempNav);



//
tempNav=new StatisticsNav();
tempMethod=new StatisticsMethod();
tempMethod.chinaName="完全随机方差分析"
tempMethod.name='F_test'
tempMethod.title="某研究者欲研究三个班级的同学体重差异。 从每个班随机选取5名同学，分别称体重如下（单位：kg），组别1=1班，2=2班，3=3班。"
tempMethod.intrPlist=["研究目的：研究三个班级的同学平均体重是否存在差异。",
    "因变量：体重，连续变量",
    "自变量：班级，分类变量，多分类",
    "统计分析方法：因为涉及3组及以上的组间均数比较，所以无法采用t检验了，此处采用方差分析。"]
tempMethod.exampleThs=[	"组别 (A列)",	"变量 (B列)"]


tempMethod.exampleRows=[
    [1,55],[1,65],[1,60],[1,58],[1,63],
    [2,50], [2,48], [2,45], [2,51], [2,49],
    [3,40], [3,43], [3,44], [3,42], [3,41],
]

tempMethod.initTableRows=[
    [1,55],[1,65],[1,60],[1,58],[1,63],
    [2,50], [2,48], [2,45], [2,51], [2,49],
        [3,40], [3,43], [3,44], [3,42], [3,41]
]

tempMethod.colLimit=2
tempMethod.apiField='F_test'
tempMethod.hasImage=true
tempMethod.imageBnOptions.rightShow=true


tempMethod.getReqData=(inputCols)=>{


    try {

        let tempMap={}
        for(let i in inputCols[0]){
            let item=inputCols[0][i]
            if(item in tempMap){
                tempMap[item]+=1
            }else{
                tempMap[item]=1
            }

        }
        for(let i in tempMap){

            if(tempMap[i] <=2 || tempMap[i]>=5000){
                debugger
                let err={}
                err[INNER_ERROR_MSG_FIELD]='每个组别数据个数需要> =3且<5000'
                return err
            }

        }

    }catch (e) {

    }

    let data={
        list1:inputCols[0],
        list2:inputCols[1]
    }
    return data;
}
tempMethod.initResData="{\"msg\":\"success\",\"code\":200,\"data\":[[60.2,48.6,42],[3.9623,2.3022,1.5811],[5,5,5],[[0.9453],[0.6853],[0.9672]],[0.2215],[54.1872],[9.8148E-7],[1.0E-4,7.4108E-7,0.0075],[\"6bccef50-cf49-11ea-a415-0242c0a82005.pdf\"],[]]}";
tempNav.title="完全随机方差分析";
tempNav.isTitle=false;
tempMethod.imageBnOptions.pdfBlockStyle="height:5.6rem;"
tempNav.active=true;
tempNav.method=tempMethod
metering_more.navs.push(tempNav);



//
tempNav=new StatisticsNav();
tempMethod=new StatisticsMethod();
tempMethod.chinaName="随机区组方差分析"
tempMethod.name='twowayF_test'
tempMethod.title="某研究者欲比较不同营养素的效果。采用随机区组设计方案，以窝作为区组标志（区组中1—8分别代表1-8窝），给断奶后的小鼠喂以三种不同的营养素A、B、和C，处理因素中1=A, 2=B, 3=C。四周后检查各种营养素组的小鼠所增体重（g）（因变量）。"
tempMethod.intrPlist=["研究目的：研究三种营养素增重效果是否有差异。",
    "因变量：增重（连续变量）",
    "自变量：营养素（分类变量，多分类）、窝别（分类变量，多分类）",
    "统计分析方法：因实验设计为典型的随机区组设计，因此采用随机区组设计资料的方差分析"]
tempMethod.exampleThs=[	"处理因素 (A列)",	"区组 (B列)","因变量 (B列)"]


tempMethod.exampleRows=[
    [1,1,57.0], [1,2,55.0], [1,3,56.1], [1,4,55.5],
    [1,5,57.7], [1,6,51.0], [1,7,57.9], [1,8,51.5],
    [2,1,64.8], [2,2,66.6], [2,3,69.5], [2,4,61.1],
    [2,5,61.8], [2,6,61.8], [2,7,69.2], [2,8,68.6],
    [3,1,76.0], [3,2,74.5], [3,3,76.5], [3,4,76.6],
    [3,5,74.7], [3,6,73.2], [3,7,71.1], [3,8,74.4]
]

tempMethod.initTableRows=[
    [1,1,57.0], [1,2,55.0], [1,3,56.1], [1,4,55.5],
    [1,5,57.7], [1,6,51.0], [1,7,57.9], [1,8,51.5],
    [2,1,64.8], [2,2,66.6], [2,3,69.5], [2,4,61.1],
    [2,5,61.8], [2,6,61.8], [2,7,69.2], [2,8,68.6],
    [3,1,76.0], [3,2,74.5], [3,3,76.5], [3,4,76.6],
    [3,5,74.7], [3,6,73.2], [3,7,71.1], [3,8,74.4]
]

tempMethod.colLimit=3
tempMethod.apiField='twowayF_test'
tempMethod.hasImage=true
tempMethod.imageBnOptions.rightShow=true


tempMethod.getReqData=(inputCols)=>{

    //


    try {

        let tempMap={}
        for(let i in inputCols[0]){
            let item=inputCols[0][i]
            if(item in tempMap){
                tempMap[item]+=1
            }else{
                tempMap[item]=1
            }
        }

        let nums=[]

        for(let i in tempMap){
            nums.push(tempMap[i])
        }

        if(Math.max(...nums) != Math.min(...nums)){
            let err={}
            err[INNER_ERROR_MSG_FIELD]='随机区组设计资料的方差分析中，每组样本数量和分区序号需要保持一致。'
            return err
        }

        try {

            let tempMap={}

            let goupSize=nums.length;

            for(let i=0;i<inputCols[0].length;i++){
                let goupTemp=inputCols[0][i];
                let goupVal=inputCols[1][i];

                if(goupTemp in tempMap){
                    tempMap[goupTemp].push(goupVal)
                }else{
                    tempMap[goupTemp]=[]
                    tempMap[goupTemp].push(goupVal)
                }
            }

            let orderMap={}

            for(let goup in tempMap){
                let orders=tempMap[goup]

                for(let i=0;i<orders.length;i++){
                    let or=orders[i]

                    if(or in orderMap){
                        orderMap[or]++;
                    }else{
                        orderMap[or]=1
                    }
                }
            }

            for(let t in orderMap){

                let tNum=orderMap[t]
                if(goupSize!=tNum){
                    let err={}
                    err[INNER_ERROR_MSG_FIELD]='随机区组设计资料的方差分析中，每组样本数量和分区序号需要保持一致。'
                    return err
                }


            }


            // eslint-disable-next-line no-empty
        }catch (e) {

        }




        // eslint-disable-next-line no-empty
    }catch (e) {

    }

    let data={
        list1:inputCols[0],
        list2:inputCols[1],
        list3:inputCols[2]
    }
    return data;
}
tempMethod.initResData="{\"msg\":\"success\",\"code\":200,\"data\":[[55.2125,65.425,74.625],[2.6471,3.5395,1.8453],[8,8,8],[[0.14],[0.1383],[0.3705]],[0.2657],[96.7567,0.9423],[6.3617E-9,0.5058],[\"1.07581948535218e-05\",\"3.92769761159428e-09\",\"3.3887955658396e-05\"],[\"32dac76a-cf4b-11ea-8d3b-0242c0a8200a.pdf\"],[]]}";
tempNav.title="随机区组方差分析";
tempNav.isTitle=false;
tempNav.active=false;
tempMethod.imageBnOptions.pdfBlockStyle="height:5.6rem;"
tempNav.method=tempMethod
metering_more.navs.push(tempNav);

tempNav=new StatisticsNav();
tempNav.title="计数资料";
tempNav.isTitle=true;
metering_more.navs.push(tempNav);


//
tempNav=new StatisticsNav();
tempMethod=new StatisticsMethod();
tempMethod.chinaName="R*C"
tempMethod.name='RCchi_test'
tempMethod.title="为研究某镇痛药的不同剂量镇痛效果是否有差别，研究人员在自愿的原则下，将条件相似的53名产妇随机分成三组，分别按三种不同剂量服用该药（第1组=1.0mg，第2组=2.5mg，第3组=5.0mg），镇痛效果如下表"
tempMethod.intrPlist=["研究目的：研究某镇痛药的不同剂量镇痛效果是否有差别",
    "因变量：是否有效（分类变量，二分类）",
    "自变量：不同剂量（分类变量，多分类）",
    "统计分析方法：R*C表卡方检验"]
tempMethod.exampleThs=[	"组别","有效 (A列)","无效 (B列)","合计"]


tempMethod.exampleRows=[
    [1,3,12,15],
    [2,11,9,20],
    [3,12,6,18],
    ["合计",26,27,53]
]

tempMethod.initTableRows=[
    [3,12],
    [11,9],
    [12,6]
]


tempMethod.apiField='RCchi_test'
tempMethod.hasImage=false


tempMethod.getReqData=(inputCols)=>{

    let list=[]
    let colNum=0;
    colNum=inputCols.length



    try {
        for(let i=0;i<colNum;i++){
            list=list.concat(...inputCols[i])
        }
    }catch (e) {

    }

    let r=list.length/colNum
    r=Math.ceil(r)
    let c=colNum

    let data={
        list,
        r,
        c
    }
    return data;
}
tempMethod.tableHeight='height: 4.35rem;'
tempMethod.colLimit=5
tempMethod.rowLimit=8
tempMethod.initResData="{\"msg\":\"success\",\"code\":200,\"data\":[[[7.3585,7.6415],[9.8113,10.1887],[8.8302,9.1698]],[7.5838],[0.0226],[\"\"]]}";
tempNav.title="R*C";
tempNav.isTitle=false;
tempNav.active=false;
tempNav.method=tempMethod
metering_more.navs.push(tempNav);






//产品
analysis_live.chinaName="生存分析";
analysis_live.name='analysis_live'
analysis_live.logoClickRoutePath="/statistics/analysis_live"
analysis_live.topLogoText="生存分析"
analysis_live.siteStatisticsId='20770475'


//产品方法和导航
tempNav=new StatisticsNav();
tempMethod=new StatisticsMethod();
tempMethod.chinaName="生存曲线Log-rank检验"
tempMethod.name='survdiff_test'
tempMethod.title="某研究者欲研究两种治疗方式对卵巢癌患者预后的影响。组别1、2分别代表两种治疗方式，生存时间代表随访的时间，生存状态1代表观察到该患者死于卵巢癌（结局时间），0代表删失值（到截止时间仍未观察到结局时间、中途失访或死于其他疾病）。问两种治疗方法对卵巢癌预后的影响是否相同？"
tempMethod.intrPlist=["研究目的：两种治疗方法对卵巢癌预后的影响是否相同","因变量：生存时间和生存状态","自变量：治疗方法（分类变量，二分类）","统计分析方法：涉及单个自变量的生存资料的分析，应采用Log-rank检验。"]




tempMethod.exampleThs=["组别 (A列)",	"生存时间 (B列)",	"生存状态 (C列)"]

tempMethod.exampleRows=[
    [1,59,1],[1,1956,0],[1,1452,1],[1,1623,1],
    [1,156,0],[1,189,1],[1,233,1],[2,75,1],
    [2,156,0],[2,266,0],[2,1352,1],[2,1149,1],
    [2,1863,1],[2,1555,0],[2,999,0],[2,563,1],
    [2,1863,1],[2,1463,1],[1,1623,1],[1,1856,0],
    [1,2189,1],[1,1633,1]
]

tempMethod.initTableRows=[
    [1,59,1],[1,1956,0],[1,1452,1],[1,1623,1],
    [1,156,0],[1,189,1],[1,233,1],[2,75,1],
    [2,156,0],[2,266,0],[2,1352,1],[2,1149,1],
    [2,1863,1],[2,1555,0],[2,999,0],[2,563,1],
    [2,1863,1],[2,1463,1],[1,1623,1],[1,1856,0],
    [1,2189,1],[1,1633,1]
]


tempMethod.colLimit=3
tempMethod.apiField='survdiff_test'
tempMethod.initResData="{\"msg\":\"success\",\"code\":200,\"data\":[[0.2851],[0.5934],[1623,1463],[[0.7071],[0.9091]],[[0.7071],[0.7955]],[[0.303],[0.3977]],[\"00f4a5b6-cf75-11ea-98ec-0242c0a82008.pdf\",\"\",\"\"]]}";
tempMethod.getReqData=(inputCols)=>{

    let data={
        list1:inputCols[0],
        list2:inputCols[1],
        list3:inputCols[2]
    }
    return data;
}
tempMethod.imageBnOptions.leftShow=true
tempMethod.imageBnOptions.rightShow=true
tempMethod.hasImage=true;
tempMethod.leftValChangeNeedImg=true
tempMethod.imageBnOptions.pdfBlockStyle='height: 10.88rem;'
tempMethod.imageBnOptions.leftOptions=[
    {"label":'常规生存曲线',"value":2},
    {"label":'置信区间',"value":3},
    {"label":'中位生存时间',"value":4}]

tempNav.title="生存曲线Log-rank检验";
tempNav.isTitle=false;
tempNav.active=true
tempMethod.imageBnOptions.pdfBlockStyle="height:9.6rem;"
tempNav.method=tempMethod
analysis_live.navs.push(tempNav);



//产品方法和导航
tempNav=new StatisticsNav();
tempMethod=new StatisticsMethod();
tempMethod.chinaName="COX回归危险因素分析"
tempMethod.name='cox'
tempMethod.title="研究肺癌患者预后的影响因素，待研究的自变量包括年龄、性别和ECOG评分。选取某医院的228名肺癌患者，记录其年龄（自变量1）、性别（自变量2，男性=1，女性=2）和ECOG评分（自变量3，0=正常，5=死亡），随访一定时间后记录其存活时间（单位：天）和生存状态（删失=1，结局事件=2）。"
tempMethod.intrPlist=["研究目的：研究肺癌患者预后的影响因素","自变量：年龄（连续变量），性别（分类变量，二分类），ECOG评分（分类变量，多分类）","因变量：生存时间与生存状态","统计分析方法：涉及多个自变量的生存资料的分析应采用cox回归"]




tempMethod.exampleThs=["生存时间 (A列)","生存状态 (B列)","自变量1 (C列)","自变量2 (D列)","自变量3 (E列)","自变量n (n列)"]

tempMethod.exampleRows=[
    [306,2,74,1,1,"..."],[455,2,68,1,0,"..."],[1010,1,56,1,0,"..."],[210,2,57,1,1,"..."],
    [883,2,60,1,0,"..."],[1022,1,74,1,1,"..."],[624,2,50,1,1,"..."],[371,2,58,1,0,"..."],
    [394,2,72,1,0,"..."],[520,2,70,2,1,"..."],[574,2,60,1,0,"..."],[118,2,70,1,3,"..."],
    [390,2,53,1,1,"..."],[12,2,74,1,2,"..."],[473,2,69,2,1,"..."],[26,2,73,1,2,"..."],
    [533,2,48,1,2,"..."],[107,2,60,2,2,"..."],[53,2,61,1,2,"..."],[122,2,62,2,2,"..."]
]

tempMethod.initTableRows=[
    [306,2,74,1,1],[455,2,68,1,0],[1010,1,56,1,0],[210,2,57,1,1],
    [883,2,60,1,0],[1022,1,74,1,1],[624,2,50,1,1],[371,2,58,1,0],
    [394,2,72,1,0],[520,2,70,2,1],[574,2,60,1,0],[118,2,70,1,3],
    [390,2,53,1,1],[12,2,74,1,2],[473,2,69,2,1],[26,2,73,1,2],
    [533,2,48,1,2],[107,2,60,2,2],[53,2,61,1,2],[122,2,62,2,2]
]


tempMethod.colLimit=10
tempMethod.apiField='cox'
tempMethod.initResData="{\"msg\":\"success\",\"code\":200,\"data\":[[0.0737,0.2004,-0.2513,2.3754,2.0147],[0.0601,0.7676,0.7235,0.003,0.1123],[1.0765,1.2219,0.7778,10.7552,7.4984],[[0.9969,1.1624],[0.3234,4.6166],[0.1934,3.1289],[2.2381,51.6839],[0.6236,90.1645]],[\"1b2879b8-cf7e-11ea-87da-0242c0a8200c.pdf\"]]}";

tempMethod.getReqData=(inputCols)=>{

    let lists=[]
    for(let i=0;i<inputCols.length;i++){
        lists.push(inputCols[i])
    }
    let data={
        lists
    }
    return data;
}

tempMethod.checkClHead=(clheads,inputCols)=>{

    let clLen=0
    try {

        clLen=clheads.length;
    }catch (e) {

    }

    // if(inputCols.length-2!=clLen){
    //
    //     let err={}
    //     err[INNER_ERROR_MSG_FIELD]='作图请输入抬头，且抬头数目需要和b数目一致，共需要'+(inputCols.length-2)+'个抬头'
    //     return err
    //
    // }
}


tempMethod.clInputInitVal='D,E'
tempMethod.clInputShow=true
tempMethod.clHeadInputShow=true
tempMethod.clHeadInputInitVal='age,gender=female,ECOG=1,ECOG=2,ECOG=3'
tempMethod.clHeadInputTip= 'OR值为1的变量将不会在图中显示'
tempMethod.hasImage=true
tempMethod.imageBnOptions.pdfBlockStyle='height: 10.88rem;'


tempNav.title="COX回归危险因素分析";
tempNav.isTitle=false;
tempNav.method=tempMethod
analysis_live.navs.push(tempNav);





//产品
analysis_relate.chinaName="相关性分析";
analysis_relate.name='analysis_relate'
analysis_relate.logoClickRoutePath="/statistics/analysis_relate"
analysis_relate.topLogoText="相关性分析"
analysis_relate.siteStatisticsId='20784145'


//产品方法和导航
tempNav=new StatisticsNav();
tempNav.title="计量资料";
tempNav.isTitle=true;
analysis_relate.navs.push(tempNav);


//产品方法和导航
tempNav=new StatisticsNav();
tempMethod=new StatisticsMethod();
tempMethod.chinaName="直线相关"
tempMethod.name='cor'
tempMethod.title="问X与Y之间是否有关联？"
tempMethod.intrPlist=["研究目的：研究X与Y之间是否有关联","变量：凝血酶浓度X（连续变量）、凝血时间Y（连续变量），此例中不存在自变量和因变量的区分。",
    "统计分析方法：直线相关"]

tempMethod.exampleThs=["序号","X (A列)","Y (B列)"]

tempMethod.exampleRows=[
    [1,1.1,14],[2,1.2,13],[3,1.0,15],[4,0.9,15],
    [5,1.2,13],[6,1.1,14],[7,0.9,16],[8,0.6,17],
    [9,1.0,14],[10,0.9,16],[11,1.1,15],[12,0.9,16],
    [13,1.1,14],[14,1.0,15],[15,0.7,17]
]


tempMethod.initTableRows=[
    [1.1,14],[1.2,13],[1.0,15],[0.9,15],
    [1.2,13],[1.1,14],[0.9,16],[0.6,17],
    [1.0,14],[0.9,16],[1.1,15],[0.9,16],
    [1.1,14],[1.0,15],[0.7,17]
]

tempMethod.colLimit=2
tempMethod.apiField='cor'
tempMethod.initResData="{\"msg\":\"success\",\"code\":200,\"data\":[[15],[0.1385],[0.2662],[-0.9265],[-8.8745],[7.057E-7],[\"06f722b0-cf87-11ea-b61c-0242c0a82009.pdf\"],[]]}";
tempMethod.getReqData=(inputCols)=>{
    let data={
        list1:inputCols[0],
        list2:inputCols[1]
    }
    return data;
}
tempMethod.imageBnOptions.pdfBlockStyle="height:5.6rem;"
tempMethod.imageBnOptions.leftShow=true
tempMethod.imageBnOptions.rightShow=false
tempMethod.hasImage=true;
tempMethod.leftValChangeNeedImg=true

tempMethod.imageBnOptions.leftOptions=[
    {"label":'无拟合线',"value":1},
    {"label":'lm',"value":2},
    {"label":'glm',"value":3},
    {"label":'gam',"value":4},
    {"label":'loess',"value":5},
    ]


tempNav.title="直线相关";
tempNav.isTitle=false;
tempNav.active=true
tempNav.method=tempMethod
analysis_relate.navs.push(tempNav);


tempNav=new StatisticsNav();
tempNav.title="计数资料";
tempNav.isTitle=true;
analysis_relate.navs.push(tempNav);



//产品方法和导航
tempNav=new StatisticsNav();
tempMethod=new StatisticsMethod();
tempMethod.chinaName="关联分析"
tempMethod.name='assocstats'
tempMethod.title="为了检查抗生素使用（变量1）与细菌多耐药性（变量2）之间的关系，某研究者对129名肠杆菌菌血症患者过去2周内抗生素的使用情况和多重耐药肠杆菌分离结果进行了调查，其结果如下表所示。试分析两变量之间的关联。"
tempMethod.intrPlist=["研究目的：研究抗生素使用与细菌多耐药性之间的关系","变量：抗生素使用（分类变量，二分类），细菌多耐药性（分类变量，二分类）",
    "统计分析方法：由于两个变量均为分类变量，因此采用分类变量的关联性分析"]

tempMethod.exampleThs=["变量1/变量2","+ (A列)","- (B列)","合计"]
tempMethod.exampleRows=[
    ["是","36","67","103"],
    ["否","1","25","26"],
    ["合计","37","92","129"]
]
tempMethod.initTableRows=[
    [36,67],
    [1,25]
]
tempMethod.colLimit=5
tempMethod.rowLimit=8
tempMethod.apiField='assocstats'
tempMethod.initResData="{\"msg\":\"success\",\"code\":200,\"data\":[[9.8193,0.0017],[0.2759],[0.2759],[0.266]]}";
tempMethod.getReqData=(inputCols)=>{

    let list=[]
    let colNum=0;
    colNum=inputCols.length
    try {
        for(let i=0;i<colNum;i++){
            list=list.concat(...inputCols[i])
        }
    }catch (e) {

    }

    let r=list.length/colNum
    r=Math.ceil(r)
    let c=colNum

    let data={
        list,
        r,
        c
    }
    return data;
}


tempMethod.tableHeight='4.2rem'
tempNav.title="关联分析";
tempNav.isTitle=false;
tempNav.active=false
tempNav.method=tempMethod
analysis_relate.navs.push(tempNav);






//产品

analysis_risk.chinaName="危险因素分析";
analysis_risk.name='analysis_risk'
analysis_risk.logoClickRoutePath="/statistics/analysis_risk"
analysis_risk.topLogoText="危险因素分析"
analysis_risk.siteStatisticsId='20779471'


//产品方法和导航
tempNav=new StatisticsNav();
tempNav.title="计量资料";
tempNav.isTitle=true;
analysis_risk.navs.push(tempNav);



//
tempNav=new StatisticsNav();
tempMethod=new StatisticsMethod();
tempMethod.chinaName="单因素线性回归"
tempMethod.name='lm'
tempMethod.title="对15名健康人凝血酶浓度X与凝血时间Y数据（表5.2）用回归分析的方法，从预测的角度来描述反应变量Y如何随自变量X的变化而变化的规律性。"
tempMethod.intrPlist=["研究目的：从预测的角度来描述反应变量Y如何随自变量X的变化而变化的规律性","因变量：凝血时间Y（连续变量）","自变量：凝血酶浓度X（连续变量）",
"统计分析方法：简单线性回归"]
tempMethod.exampleThs=["序号","X (A列)","Y (B列)"]
tempMethod.exampleRows=[
    [1,1.1,14],[2,1.2,13],[3,1.0,15],[4,0.9,15],
    [5,1.2,13],[6,1.1,14],[7,0.9,16],[8,0.6,17],
    [9,1.0,14],[10,0.9,16],[11,1.1,15],[12,0.9,16],
    [13,1.1,14],[14,1.0,15],[15,0.7,17]
]
tempMethod.initTableRows=[
    [1.1,14],[1.2,13],[1.0,15],[0.9,15],
    [1.2,13],[1.1,14],[0.9,16],[0.6,17],
    [1.0,14],[0.9,16],[1.1,15],[0.9,16],
    [1.1,14],[1.0,15],[0.7,17]
]
tempMethod.colLimit=2
tempMethod.apiField='lm'
tempMethod.hasImage=false
tempMethod.imageBnOptions.rightShow=false

tempMethod.getReqData=(inputCols)=>{
    let data={
        list1:inputCols[0],
        list2:inputCols[1]
    }
    return data;
}


tempMethod.initResData="{\"msg\":\"success\",\"code\":200,\"data\":[[15],[21.7739],[5.6071E-13],[-6.9802],[7.057E-7],[0.8583]]}";
tempNav.title="单因素线性回归";
tempNav.isTitle=false;
tempNav.active=true
tempNav.method=tempMethod
analysis_risk.navs.push(tempNav);





//
tempNav=new StatisticsNav();
tempMethod=new StatisticsMethod();
tempMethod.chinaName="多因素线性回归"
tempMethod.name='multi_lm'
tempMethod.title="为研究男性高血压患者血压（因变量）与年龄（自变量1）、吸烟（自变量2）的关系，随机测量了10名40岁以上男性的血压（mmHg）、年龄（岁）、吸烟史。 吸烟：0为不吸，1为过去或现在吸烟。"
tempMethod.intrPlist=["研究目的：研究男性高血压患者血压与年龄、吸烟的关系","因变量：凝血时间Y（连续变量）","自变量：年龄（连续变量）、吸烟史（分类变量，二分类）",
    "统计分析方法：因为因变量为计量资料，且自变量多于1个，因此采用多重线性回归。"]
tempMethod.exampleThs=["序号","因变量 (A列)","自变量1 (B列)","自变量2 (C列)","..."]

tempMethod.exampleRows=[
    ["1","135","45","0","..."],
    ["2","122","41","0","..."],
    ["3","130","49","0","..."],
    ["4","158","52","0","..."],
    ["5","146","54","1","..."],
    ["6","129","47","1","..."],
    ["7","162","60","1","..."],
    ["8","157","54","1","..."],
    ["9","144","44","1","..."],
    ["10","180","64","1","..."]
]
tempMethod.initTableRows=[
    [135,45,0],
    [122,41,0],
    [130,49,0],
    [158,52,0],
    [146,54,1],
    [129,47,1],
    [162,60,1],
    [157,54,1],
    [144,44,1],
    [180,64,1]
]
tempMethod.colLimit=10
tempMethod.apiField='multi_lm'
tempMethod.hasImage=false
tempMethod.imageBnOptions.rightShow=false
tempMethod.clInputShow=true
tempMethod.clInputInitVal="C"

tempMethod.getReqData=(inputCols)=>{

    let lists=[]
    for(let i=0;i<inputCols.length;i++){
        lists.push(inputCols[i])
    }
    let data={
        lists
    }
    return data;
}

tempMethod.initResData="{\"msg\":\"success\",\"code\":200,\"data\":[[34.9381,2.1671,1.3997],[[-20.0318,89.908],[1.0144,3.3198],[-14.8004,17.5998]],[0.1766,0.003,0.8439],[0.7987]]}";

tempNav.title="多因素线性回归";
tempNav.isTitle=false;
tempNav.method=tempMethod
analysis_risk.navs.push(tempNav);


tempNav=new StatisticsNav();
tempNav.title="计数资料";
tempNav.isTitle=true;
analysis_risk.navs.push(tempNav);



//
tempNav=new StatisticsNav();
tempMethod=new StatisticsMethod();
tempMethod.chinaName="Logistic回归"
tempMethod.name='logistic'
tempMethod.title="研究肺癌的危险因素，包括吸烟、性别、年龄段。因变量中：1=肺癌，0=正常；自变量1中：1=吸烟，0=不吸烟；自变量2中：1=男性，2=女性；自变量3中：1=60岁以上，2=60岁以下。"
tempMethod.intrPlist=["研究目的：研究肺癌的危险因素","因变量：是否患肺癌（分类变量，二分类）","自变量：吸烟（分类变量，二分类）、性别（分类变量，二分类）、年龄段（分类变量，二分类）",
    "统计分析方法：因为因变量为二分类变量，所以采用Logistic回归"]
tempMethod.exampleThs=["因变量 (A列)","自变量1 (B列)","自变量2 (C列)","自变量3 (D列)","自变量n (n列)"]

tempMethod.exampleRows=[
    [1,1,1,0,"..."],
    [1,0,1,1,"..."],
    [0,1,1,1,"..."],
    [0,0,1,1,"..."],
    [1,1,2,1,"..."],
    [1,0,2,1,"..."],
    [0,1,2,1,"..."]
]

tempMethod.initTableRows=[
    [1,1,1,0],
    [1,0,1,1],
    [0,1,1,1],
    [0,0,1,1],
    [1,1,2,1],
    [1,0,2,1],
    [0,1,2,1]
]

tempMethod.colLimit=10
tempMethod.apiField='logistic'
tempMethod.hasImage=false
tempMethod.imageBnOptions.rightShow=false
tempMethod.clInputShow=true
tempMethod.clInputInitVal="B,C,D"
tempMethod.clHeadInputInitVal="smoke,female,age<60"
tempMethod.clHeadInputShow=true
tempMethod.clHeadInputTip="OR值为1的变量将不会在图中显示"

tempMethod.getReqData=(inputCols)=>{

    let lists=[]
    for(let i=0;i<inputCols.length;i++){
        lists.push(inputCols[i])
    }
    let data={
        lists
    }
    return data;
}
tempMethod.checkClHead=(clheads,inputCols)=>{

    let clLen=0
    try {
         clLen=clheads.length;
    }catch (e) {

    }


    if(inputCols.length-1!=clLen){

            let err={}
            err[INNER_ERROR_MSG_FIELD]='作图请输入抬头，且抬头数目需要和b数目一致，共需要'+(inputCols.length-1)+'个抬头'
            return err

    }
}

tempMethod.commonErrorMsg='。因变量及各个自变量水平需>=2'
tempMethod.hasImage=true

tempMethod.initResData="{\"msg\":\"success\",\"code\":200,\"data\":[[16.5073,-8.4557,8.4444,-16.4978],[0.8235,0.8612,0.8614,0.8236],[1.47576375075E7,2.0E-4,4649.0712,6.8405E-8],[[1.4302E-56,1.52275807154122E70],[1.4275E-45,3.168586707869E37],[3.1204E-38,6.92652992980161E44],[6.6301E-71,7.05755976479914E55]],[\"34a2c900-cfb7-11ea-a94d-0242c0a8200d.pdf\"]]}";
tempNav.title="logistic";
tempNav.isTitle=false;
tempNav.method=tempMethod
analysis_risk.navs.push(tempNav);



//产品
analysis_diagnose.chinaName="诊断性分析";
analysis_diagnose.name='analysis_diagnose'
analysis_diagnose.logoClickRoutePath="/statistics/analysis_diagnose"
analysis_diagnose.topLogoText="诊断性分析"
analysis_diagnose.siteStatisticsId='20784121'

//
tempNav=new StatisticsNav();
tempMethod=new StatisticsMethod();
tempMethod.chinaName="诊断性分析"
tempMethod.name='roc'

tempMethod.title="利用113例动脉瘤疾病的观察数据，比较两种biomarker（S100b和NDKA）的诊断表现。自变量1：S100b；自变量2：NDKA；因变量：预后好good坏poor"
tempMethod.intrPlist=["研究目的：比较两种biomarker的诊断表现","因变量：预后好坏（分类变量，二分类）","自变量：S100b或NDKA",
    "统计分析方法：AUC"]

tempMethod.exampleThs=["因变量 (A列)","自变量1 (B列)","自变量2 (C列)","自变量n (n列)"]

tempMethod.exampleRows=[
    ["Good","0.13","3.01","..."],
        ["Good","0.14","8.54","..."],
        ["Good","0.10","8.09","..."],
        ["Good","0.04","10.42","..."],
        ["Poor","0.04","10.42","..."],
        ["...","...","...","..."]
]

tempMethod.initTableRows=[
    ["Good",0.13,3.01],
    ["Good",0.14,8.54],
    ["Good",0.1,8.09],
    ["Good",0.04,10.42],
    ["Poor",0.13,17.4],
    ["Poor",0.1,12.75],
    ["Good",0.47,6],
    ["Poor",0.16,13.2],
    ["Good",0.18,15.54],
    ["Good",0.1,6.01],
    ["Poor",0.12,15.96],
    ["Good",0.1,17.86],
    ["Poor",0.44,5.18],
    ["Poor",0.71,8.9],
    ["Good",0.04,13.41],
    ["Good",0.08,20.75],
    ["Poor",0.49,11.6],
    ["Good",0.04,16.11],
    ["Poor",0.07,32.37],
    ["Poor",0.33,54.82],
    ["Poor",0.09,32.41],
    ["Good",0.09,49.94],
    ["Poor",0.07,40.34],
    ["Good",0.11,9.47],
    ["Good",0.07,6.29],
    ["Good",0.17,12.53],
    ["Good",0.07,6.54],
    ["Good",0.11,6.3],
    ["Good",0.13,80.3],
    ["Good",0.19,12.8],
    ["Good",0.05,9.8],
    ["Good",0.16,9.81],
    ["Poor",0.41,9.85],
    ["Good",0.14,18.21],
    ["Good",0.34,5.03],
    ["Poor",0.35,14.04],
    ["Poor",0.48,21.93],
    ["Good",0.09,8.02],
    ["Poor",0.96,7.42],
    ["Poor",0.25,8.38],
    ["Good",0.5,6.59],
    ["Good",0.46,9.63],
    ["Good",0.16,13.12],
    ["Good",0.07,7.96],
    ["Good",0.43,14.34],
    ["Good",0.45,41.43],
    ["Good",0.11,7.63],
    ["Good",0.08,7.06],
    ["Good",0.09,12.59],
    ["Poor",0.86,13.56]
]



tempMethod.colLimit=10
tempMethod.apiField='roc'
tempMethod.hasImage=true
tempMethod.imageBnOptions.rightShow=true


tempMethod.getReqData=(inputCols)=>{

    let lists=[]
    for(let i=0;i<inputCols.length;i++){
        lists.push(inputCols[i])
    }
    let data={
        lists
    }
    return data;
}
tempMethod.tableDataIsNum=false
tempMethod.commonErrorMsg=''
tempMethod.hasImage=true
tempMethod.imageBnOptions.pdfBlockStyle="height:5.6rem;"
tempMethod.initResData="{\"msg\":\"success\",\"code\":200,\"data\":[[[0.5484,0.7077,0.867],[0.5042,0.6631,0.822]],{},[[1,2,0.7371]],[\"898d4ede-d22a-11ea-9fa4-0242c0a86004.pdf\"]]}";
tempNav.title="诊断性分析";
tempNav.active=true
tempNav.isTitle=false;
tempNav.method=tempMethod
analysis_diagnose.navs.push(tempNav);
