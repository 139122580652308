export class StatisticsMethod {

    constructor() {
        this.chinaName=''
        this.name='';
        this.title="";
        this.intrPlist=[];
        this.exampleThs=[];
        this.exampleRows=[];

        this.apiField=''
        this.initTableRows=[];
        this.hasImage=false
        this.tableDataIsNum=true;

        this.commonErrorMsg=''

        this.colLimit=5
        this.rowLimit=-1
        this.tableHeight="5.6rem"
        this.clInputShow=false;
        this.clInputInitVal=''
        this.clHeadInputShow=false;
        this.clHeadInputInitVal=''
        this.clHeadInputTip=''
        this.leftValChangeNeedImg=false;
        this.imageBnOptions={
            pdfBlockStyle:'overflow: hidden;height:8.6rem;',
            leftShow:false,
            rightShow:false,
            leftOptions:[],
            rightOptions:[
                {"label":'Lancet',"value":1},
                {"label":'Nature',"value":2},
                {"label":'Science',"value":3},
                {"label":'JAMA',"value":4},
                {"label":'NEJM',"value":5},
                {"label":'JOC',"value":6}],

        }
        // eslint-disable-next-line no-unused-vars
        this.getReqData=function(inputCols){

        }

        // eslint-disable-next-line no-unused-vars
        this.checkCl=function(cls){

            return {}
        }

        // eslint-disable-next-line no-unused-vars
        this.checkClHead=function(clheads){

            return {}
        }

        this.initResData=""
        /**
         * title="某研究者欲研究男性和女性的体重差异。随机选取5例男性和5例女性,分别称体重如下（单位：kg）,A列为男性,B列为女性。"
         :ps='["研究目的：研究男性和女性的体重均数是否存在差异。","因变量：体重，连续变量","自变量：性别，分类变量，二分类","统计分析方法：两独立样本均数t检验"]'
         :table-ths='["序号",	"第一组 (A列)",	"第二组 (B列)"]'
         :table-rows="[[1,55,50],[2,65,48],[3,60,45],[4,58,51],[5,63,49]]"
         */
    }


}

export class StatisticsNav {

    constructor() {
        this.active=false;
        this.isTitle=false;
        this.title=""
        this.flag=""
        this.field=''
        this.all=false
        this.method={}
        this.sons=[]
        this.show=true
        this.goup=''
        this.goupExclude=false
        this.toolTip=''
        this.disable=false
        this.excludeItems={}
        this.isExcludeItem=false
        this.showNotActive=false
    }

}



export class StatisticsProduct {

    constructor() {
        this.chinaName=''
        this.name=''
        this.navs=[]
        this.logoClickRoutePath="";
        this.topLogoText="";
        this.siteStatisticsId=''
    }
}


export class MulSortCondition {

    constructor() {

        this.field=''
        this.opt=0
        this.vals=[]
        this.val=''
    }


}

export class OrderInfo{
    constructor() {
        this.code_url='';
        this.out_trade_no='';
        this.total_fee=0;
        this.productName=''
    }
}
export default {
    
    
    
    
}