

// let debug=false;
// let ENCRYPT_RESTRESULT=true


let debug=true;
let ENCRYPT_RESTRESULT=false

let home_81=false;
let serverBasessr='http://127.0.0.1:777'
if(!debug){
    serverBasessr='http://manager:58001'
}
export default {
    serverBasessr,
    debug,ENCRYPT_RESTRESULT,home_81
}
