import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

//解决vue路由重复导航错误
//获取原型对象上的push函数
// let originalPush = VueRouter.prototype.push
// //修改原型对象中的push方法
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }
//
// originalPush = VueRouter.replace;
// VueRouter.replace = function replace(location) {
//   return originalPush.call(this, location).catch(err => err)
// }

const router = new VueRouter({
  mode: 'history',  //去掉url中的#
  routes: [

    {
      path: '/',
      name: 'home',
      meta: {
        hideHeader: false
      },
      component: () => import('@views/gojournal/Journal.vue')
    },
    {
      path: '/journal',
      name: 'journal',
      meta: {
        hideHeader: false
      },
      component: () => import('@views/gojournal/Journal.vue')
    },
    {
      path: '/journal_test',
      name: 'journal_test',
      meta: {
        hideHeader: false
      },
      component: () => import('@views/gojournal/JournalTest.vue')
    },
    // {
    //   path: '/example',
    //   name: 'example',
    //   meta: {
    //     hideHeader: false
    //   },
    //   component: () => import('@views/gojournal/Example.vue')
    // },
    // {
    //   path: '/pay',
    //   name: 'pay',
    //   meta: {
    //     hideHeader: false
    //   },
    //   component: () => import('@views/gojournal/Pay')
    // },
    // {
    //   path: '/individuation',
    //   name: 'individuation',
    //   meta: {
    //     hideHeader: false
    //   },
    //   component: () => import('@views/gojournal/Individuation')
    // },
  ],
  // routes
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
